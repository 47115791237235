import './App.scss'
import { useEffect } from 'react'

function App() {
  useEffect(() => {
    window.location.replace('https://fridaysnap.de/')
  }, []);

  return (
    <>
      Weiterleitung zur Website erfolgt
    </>
  )
}

export default App

/*
Upload Pipeline:
1. Receive image/video
1. Calculate image (video?) hash
1. Create Upload request
  1. Cancel if cancelled (duplicate hash)
1. Upload to endpoint
1. Confirm delivery of upload and receive additional data
*/
import { create } from 'zustand'
import { produce } from 'immer'
import { Album } from '@/models/album.ts'
import { AlbumFile } from '@/models/albumFile.ts'
import HeyGame from '@/models/heyGame.ts'

export enum UploadFileStatus {
  Added = 'added',
  InProgress = 'in_progress',
  HashCompleted = 'hash_completed',
  Uploading = 'uploading',
  Success = 'success',
  Failed = 'failed',
}

export function isStatusFinalState(status: UploadFileStatus) {
  return status === UploadFileStatus.Success || status === UploadFileStatus.Failed
}

interface UploadPostParts {

}

export interface UploadFile {
  randomId: string
  file: File
  game?: HeyGame
  fileHash?: string | null
  uploadPost?: string | Array<UploadPostParts> | null
  status: UploadFileStatus
  album: Album
  bytesUploaded: number | null
}

interface UseUploadsState {
  uploadQueue: Array<UploadFile>
  uploadedFiles: Record<Album['id'], Array<AlbumFile>>
  deletedFiles: Set<string>
}

interface RegisterUploadFile {
  file: File
  game?: HeyGame
}

interface UseUploadsActions {
  registerFileUploads: (album: Album, files: Array<RegisterUploadFile>) => Array<UploadFile>
  updateFileUpload: (uploadFile: UploadFile) => void
  addUploadedFiles: (album: Album, files: Array<AlbumFile>) => void
  addDeletedFiles: (files: Array<AlbumFile>) => void
  removeUploadedFilesForAlbum: (album: Album) => void
  // setDrive: (drive: HeyDrive) => void
}

function initUploadFile(album: Album, registerFile: RegisterUploadFile): UploadFile {
  let randomId: string | undefined
  try {
    randomId = crypto.randomUUID()
  } catch (e) {
    console.log('Err generating random id', e)
    randomId = `${Math.random()}:${Math.random()}:${Math.random()}`
  }

  return {
    randomId,
    file: registerFile.file,
    game: registerFile.game,
    fileHash: undefined,
    uploadPost: undefined,
    status: UploadFileStatus.Added,
    bytesUploaded: null,
    album,
  }
}

export const useUploadQueue = create<UseUploadsState & UseUploadsActions>((set) => ({
  uploadQueue: [],
  uploadedFiles: {},
  deletedFiles: new Set(),
  registerFileUploads(album: Album, files) {
    const uploadQueueFiles = files.map(file => initUploadFile(album, file))
    set(produce((state: UseUploadsState) => {
      state.uploadQueue.push(...uploadQueueFiles)
    }))

    return uploadQueueFiles
  },
  updateFileUpload(file) {
    set(produce((state: UseUploadsState) => {
      const i = state.uploadQueue.findIndex(uploadFile => uploadFile.randomId === file.randomId)
      if (i >= 0) {
        state.uploadQueue[i] = file
      }
    }))
  },
  addUploadedFiles(album: Album, files: Array<AlbumFile>) {
    set(produce((state: UseUploadsState) => {
      const currentlyUploaded = state.uploadedFiles[album.id] || []

      state.uploadedFiles[album.id] = [...files, ...currentlyUploaded, ]
    }))
  },
  addDeletedFiles(files: Array<AlbumFile>) {
    set(produce((state: UseUploadsState) => {
      const newFiles = new Set(state.deletedFiles)

      for (const file of files) {
        newFiles.add(file.id)
      }

      state.deletedFiles = newFiles
    }))
  },
  removeUploadedFilesForAlbum(album: Album) {
    set(produce((state: UseUploadsState) => {
      state.uploadedFiles = {
        ...state.uploadedFiles,
        [album.id]: [],
      }
    }))
  },
}))

import { useState } from 'react'
import { AlbumFile } from '@/models/albumFile.ts'

export interface FileSelectionType {
  toggleFileSelection: (file: AlbumFile) => void;
  isSelecting: boolean;
  isAllSelected: boolean;
  setIsSelecting: (selected: boolean) => void;
  setIsAllSelected: (value: boolean) => void
  getSelectedAlbumFileIds: () => Array<string>
  isFileSelected: (file: AlbumFile) => boolean
}

export default function useFileSelection(): FileSelectionType {
  const [isSelecting, _setIsSelecting] = useState(false)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<Record<string, boolean>>({})

  function setIsSelecting(selected: boolean) {
    if (selected) {
      // Reset status
      setIsAllSelected(false)
      setSelectedFiles({})
    }

    _setIsSelecting(selected)
  }

  function isFileSelected(file: AlbumFile): boolean {
    return isAllSelected || !!selectedFiles[file.id]
  }

  function toggleFileSelection(file: AlbumFile) {
    setSelectedFiles({
      ...selectedFiles,
      [file.id]: !isFileSelected(file),
    })
  }

  function getSelectedAlbumFileIds(): Array<string> {
    return Object.keys(selectedFiles).filter(file => selectedFiles[file])
  }

  return {
    isSelecting,
    setIsSelecting,
    isAllSelected,
    setIsAllSelected,
    toggleFileSelection,
    getSelectedAlbumFileIds,
    isFileSelected,
  }
}

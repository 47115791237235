import { ThemeDetails } from '@/components/Theme/ThemeSelection.tsx'

export default function ThemeColors({theme, onClick, big, onPrimaryClick, onNeutralClick, onContentClick}: { theme: ThemeDetails, onClick?: () => void, big?: boolean, onPrimaryClick?: () => void, onNeutralClick?: () => void, onContentClick?: () => void, }) {
  if (big) {
    return <div className='inline-flex bg-[white] flex-col gap-2 p-2 rounded-full shadow' onClick={onClick}>
      <div className='rounded-full w-[2rem] h-[2rem] shadow-lg' onClick={onPrimaryClick} style={{backgroundColor: theme.primary}}></div>
      <div className='rounded-full w-[2rem] h-[2rem] shadow-lg' onClick={onNeutralClick} style={{backgroundColor: theme.neutral}}></div>
      <div className='rounded-full w-[2rem] h-[2rem] shadow-lg' onClick={onContentClick} style={{backgroundColor: theme.content}}></div>
    </div>
  }

  return <div className='inline-flex bg-[white] flex-col gap-2 p-2 rounded-full shadow' onClick={onClick}>
    <div className='rounded-full w-[1.5rem] h-[1.5rem] shadow-lg' onClick={onPrimaryClick} style={{backgroundColor: theme.primary}}></div>
    <div className='rounded-full w-[1.5rem] h-[1.5rem] shadow-lg' onClick={onNeutralClick} style={{backgroundColor: theme.neutral}}></div>
    <div className='rounded-full w-[1.5rem] h-[1.5rem] shadow-lg' onClick={onContentClick} style={{backgroundColor: theme.content}}></div>
  </div>
}

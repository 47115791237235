import { useCallback, useState } from 'react'

export default function useCollapses() {
  const [selectedCollapse, setSelectedCollapse] = useState<string | null>(null)

  const collapse = useCallback((key: string) => {
    return {
      key,
      open: key === selectedCollapse,
      onClick: () => setSelectedCollapse(key === selectedCollapse ? null : key)
    }
  }, [selectedCollapse])

  return {
    selectedCollapse,
    collapse,
  }
}

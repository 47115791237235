import { Button, Input, Modal } from 'react-daisyui'
import { RefObject, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { Album } from '@/models/album.ts'

export default function CreateProfileModal(
  { album, onRegister, innerRef }:
    { album: Album, onRegister: () => void, innerRef: RefObject<HTMLDialogElement> }
) {
  const { createGuestProfile } = useClient()
  const { setProfile , setAuthenticationToken } = useProfile()
  const [isCreatingProfile, setIsCreatingProfile] = useState(false)
  const [displayName, setDisplayName] = useState('')

  async function onGuestSaveClick(e: any) {
    e.preventDefault()
    if (isCreatingProfile) {
      return
    }

    setIsCreatingProfile(true)

    try {
      const res = await createGuestProfile(displayName, album)
      const profile = res.data
      setProfile(profile)
      setAuthenticationToken(res.headers['ha-token'])
      innerRef.current?.close()
      onRegister()
    } catch (e) {
      // TODO: error handling
      console.error(e)
    } finally {
      setIsCreatingProfile(false)
    }
  }

  // useEffect(() => {
  //   if (!profileCreateRef.current) {
  //     return
  //   }
  //
  //   if (open) {
  //     profileCreateRef.current.showModal()
  //   } else {
  //     profileCreateRef.current.close()
  //   }
  // }, [open, profileCreateRef]);

  return <>
    <Modal ref={innerRef} responsive className='bg-neutral'>
      <Modal.Header>
        {album!.title}
        <form method="dialog">
          <button disabled={isCreatingProfile} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <form className='flex flex-col items-center gap-5 max-w-[20rem] mx-auto' onSubmit={onGuestSaveClick}>
          <p className='text-center'>
            Bitte nenne uns deinen Namen, damit wir wissen von wem die Bilder kommen :)
          </p>
          <label className='form-control'>
            <div className='label'>
              <span className="label-text">Dein Name</span>
            </div>
            <Input color='primary' placeholder='Name' value={displayName} disabled={isCreatingProfile}
                   onChange={e => setDisplayName(e.target.value)}/>
          </label>
          <div>
            <Button type='submit' color='primary' loading={isCreatingProfile}>
              Speichern
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  </>
}

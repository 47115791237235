import { AlbumFile, prepareForLightbox } from '@/models/albumFile.ts'
import Lightbox, { ToolbarButtonKey } from 'yet-another-react-lightbox'
import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import Share from "yet-another-react-lightbox/plugins/share";
import Download from "yet-another-react-lightbox/plugins/download";
import Video from "yet-another-react-lightbox/plugins/video";
import { IconTrash } from '@tabler/icons-react';
import { hasPermissionsDeleteFile } from '@/controller/permissions.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { Button, Modal } from 'react-daisyui'
import { useClient } from '@/stores/useClient.ts'
import { useUploadQueue } from '@/stores/useUploadQueue.ts'
import { Captions } from 'yet-another-react-lightbox/plugins'
import { t } from 'i18next'
import { useDrives } from '@/stores/useDrives.ts'

export default function FilesSlideshow(
  { files, selectedFileIndex, onSelectFileIndex }:
    { files: Array<AlbumFile>, selectedFileIndex: number | null, onSelectFileIndex: (fileIndex: number | null) => void }
) {
  const slides = useMemo(() => {
    return files.map(file => prepareForLightbox(file))
  }, [files])
  const { profile } = useProfile()
  const { currentDrive } = useDrives()!
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const { deleteFile } = useClient()
  const { addDeletedFiles } = useUploadQueue()

  console.log('fileIndex', selectedFileIndex)
  if (selectedFileIndex == null) {
    return <></>
  }

  const currentFile = files[selectedFileIndex]

  // Happens is you delete something
  if (!currentFile) {
    return <></>
  }


  function onDeleteClick() {
    deleteModalRef.current?.showModal()

  }
  async function onDeleteConfirm() {
    setIsDeleting(true)
    try {
      const file = files[selectedFileIndex!]
      await deleteFile(file)
      addDeletedFiles([file])
      deleteModalRef.current?.close()
    } catch (e) {
      console.error(e)
      alert('Error deleting file: ' + JSON.stringify(e))
    } finally {
      setIsDeleting(false)
    }

  }
  const toolbarButtons: (ToolbarButtonKey | React.ReactNode)[] = ['close']
  if (profile && currentDrive && hasPermissionsDeleteFile(currentFile, currentDrive, profile)) {
    if (currentFile.is_demo) {
      // toolbarButtons.unshift(
      //   <div color='primary' key='demo' className='flex h-full bg-primary'>Demo Beispiel</div>
      // )
    } else {
      toolbarButtons.unshift(
        <button key='delete-file' className='yarl__button' onClick={onDeleteClick}>
          <IconTrash />
        </button>
      )
    }
  }

  return <>
    <Modal ref={deleteModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {t('common.deleteQst')}
        <form method="dialog">
          <button disabled={isDeleting} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center flex flex-col gap-5'>
          <p>
            {t('common.deleteRly')}
          </p>
          <div className='flex flex-col gap-3'>
            <Button className='text-[#FFFFFF]' onClick={onDeleteConfirm} color='error' disabled={isDeleting} loading={isDeleting}>
              {t('common.delete')}
            </Button>
            <form className='w-full' method="dialog">
              <Button wide color='ghost' disabled={isDeleting}>
                {t('common.cancel')}
              </Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Lightbox
      open={true}
      slides={slides}
      index={selectedFileIndex}
      close={() => onSelectFileIndex(null)}
      on={{
        view: ({ index }) => onSelectFileIndex(index),
      }}
      toolbar={{
        buttons: toolbarButtons,
      }}
      plugins={[
        Video,
        Download,
        Share,
        Captions,
      ]}
    />
  </>
}

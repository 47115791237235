import { useFilesContext } from '@/contexts/filesContext.tsx'
import { ReactNode, useRef, useState } from 'react'
import { Button, Checkbox, Input, Textarea } from 'react-daisyui'
import LoadBig from '@/components/LoadBig/LoadBig.tsx'
import { useClient } from '@/stores/useClient.ts'
import { HeyDrive } from '@/models/drive.ts'
import { Album } from '@/models/album.ts'
import { useDrives } from '@/stores/useDrives.ts'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { makeKey } from '@/stores/useFiles.ts'
import { defaultThemes, ThemeDetails } from '@/components/Theme/ThemeSelection.tsx'
import ThemeColors from '@/components/Theme/ThemeColors.tsx'
import { useHeyTheme } from '@/contexts/heyTheme.tsx'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'

interface OnboardingState {
  eventName: string | null
  albumName: string | null
  albumDescription: string | null
  albumThumbnail: File | null
  albumIsPrivate: boolean | null
  colorPrimary: string | null
  colorNeutral: string | null
  colorContent: string | null
}

enum PageIndex {
  Hello = 'hello',
  Drive = 'drive',
  Album = 'album',
  Theme = 'theme',
  AlbumDescription = 'album-description',
  // AlbumThumbnail = 'album-thumbnail',
  AlbumPrivate = 'album-private',
  // Send = 'send',
}

const Pages = [
  PageIndex.Hello,
  PageIndex.Drive,
  PageIndex.Album,
  // PageIndex.AlbumThumbnail,
  PageIndex.Theme,
  PageIndex.AlbumPrivate,
  // PageIndex.Send,
  PageIndex.AlbumDescription,
]

export default function OnboardingPage() {
  const { currentAlbum: album } = useFilesContext()!
  const { currentDrive } = useDrives()
  const [isSending, setIsSending] = useState(false)
  const [onboardingState, setOnboardingState] = useState<OnboardingState>({
    eventName: null,
    albumName: null,
    albumDescription: null,
    albumThumbnail: null,
    albumIsPrivate: null,
    colorPrimary: null,
    colorNeutral: null,
    colorContent: null,
  })
  const [currentPage, setCurrentPage] = useState(PageIndex.Hello)
  const navigate = useNavigate()
  const { updateAlbum, updateDrive } = useClient()
  const [isDone, setIsDone] = useState(false)
  const queryClient = useQueryClient()

  const pageMapping: Record<PageIndex, any> = {
    [PageIndex.Hello]: HelloPage,
    [PageIndex.Drive]: DrivePage,
    [PageIndex.Album]: AlbumPage,
    [PageIndex.Theme]: AlbumThemePage,
    [PageIndex.AlbumPrivate]: AlbumPrivatePage,
    [PageIndex.AlbumDescription]: AlbumDescriptionPage,
  }

  async function onSubmit() {
    if (isSending) {
      return
    }

    setIsSending(true)
    try {
      const driveData = new FormData()
      let hasDriveData = false
      if (onboardingState.eventName) {
        driveData.append('title', onboardingState.eventName)
      }

      console.log('onboardingState', onboardingState)
      if (onboardingState.colorPrimary && onboardingState.colorContent && onboardingState.colorNeutral) {
        function fixColor(colorWithHex: string): string {
          return colorWithHex.slice(1)
        }

        driveData.append('color_primary', fixColor(onboardingState.colorPrimary))
        driveData.append('color_neutral', fixColor(onboardingState.colorNeutral))
        driveData.append('color_content', fixColor(onboardingState.colorContent))
        hasDriveData = true
      }

      driveData.append('is_show_onboarding', 'false')
      hasDriveData = true

      if (hasDriveData) {
        await updateDrive(currentDrive!.id, driveData)
      }

      const albumData = new FormData()
      let hasAlbumData = false
      if (onboardingState.albumIsPrivate != null) {
        albumData.append('is_private', onboardingState.albumIsPrivate ? 'true' : 'false')
        hasAlbumData = true
      }

      if (onboardingState.albumName != null) {
        albumData.append('title', onboardingState.albumName)
        hasAlbumData = true
      }

      if (onboardingState.albumDescription != null) {
        albumData.append('description', onboardingState.albumDescription)
        hasAlbumData = true
      }

      if (onboardingState.albumThumbnail != null) {
        albumData.append('thumbnail', onboardingState.albumThumbnail)
        hasAlbumData = true
      }

      if (hasAlbumData) {
        await updateAlbum(album!.id, albumData)
      }

      if (hasDriveData) {
        await queryClient.invalidateQueries({
          queryKey: ['drive', currentDrive!.id],
        })
      }

      if (hasAlbumData) {
        await queryClient.invalidateQueries({
          queryKey: makeKey(album!.id),
        })
      }

      setIsDone(true)
    } catch (e) {
      console.error('error onboarding', e)
      alert('Beim Senden ist etwas fehlgeschlagen. Bitte probiere es erneut.')
    } finally {
      setIsSending(false)
    }
  }

  if (!album || !currentDrive) {
    return <LoadBig />
  }

  if (isDone) {
    return <SubPage
      title='Das wars!'
      state={onboardingState}
      drive={currentDrive}
      album={album}
      // @ts-ignore
      setState={setOnboardingState}
      isSaving={false}
      onLetsGo={() => navigate(`/d/${currentDrive.id}`)}
    >
      <div className='flex flex-col gap-3'>
        <p className='text-center'>Wir wüschen viel Spaß bei der Nutzung von FridaySnap 😍</p>
      </div>
    </SubPage>
  }

  const CurrentPage = pageMapping[currentPage]


  const pageIndex = Pages.indexOf(currentPage)
  const isFirstPage = pageIndex === 0
  const isLastPage = pageIndex + 1 === Pages.length
  const onBack = !isFirstPage ? () => setCurrentPage(Pages[pageIndex - 1]) : undefined
  const onNext = !isLastPage ? () => setCurrentPage(Pages[pageIndex + 1]) : undefined
  const onClose = isFirstPage ? () => navigate(`/d/${currentDrive.id}`) : undefined
  const onSave = isLastPage ? () => onSubmit() : undefined

  return <div>
    <CurrentPage
      index={pageIndex}
      pageCount={Pages.length}
      state={onboardingState}
      album={album}
      drive={currentDrive}
      setState={setOnboardingState}
      onNext={onNext}
      onSave={onSave}
      onBack={onBack}
      onClose={onClose}
      isSaving={isSending}
    />
  </div>
}

function updateProp(state: OnboardingState, setOnboardingState: (state: Partial<OnboardingState>) => void, stateName: keyof OnboardingState, setState: (value: any) => void, value: any) {
  setOnboardingState({
    ...state,
    [stateName]: value,
  })
  setState(value)
}

function HelloPage(props: PageProps) {
  return <SubPage
    title='FridaySnap Einrichtung'
    description='Lass uns gerne zusammen dein FridaySnap einrichten'
    {...props}
  >
    <p className='text-center'>
      Das dauert auch nur eine Minute 😊
    </p>
  </SubPage>
}

function DrivePage(props: PageProps) {
  const { state, setState, drive } = props
  const [eventName, setEventName] = useState(state.eventName || '')

  return <SubPage
    title='Name deiner Galerie'
    {...props}
  >
    <Input className='w-full' type='text' value={eventName} placeholder={drive.title} onChange={e => updateProp(state, setState, 'eventName', setEventName, e.target.value)} />
    <p className='mt-2 text-center'>Dieser Name wird immer oben angezeigt</p>
    <p className='mt-5 text-center'>Beispiel: "Laura & Thomas" oder "Gemeinsam Erinnerungen sammeln"</p>
  </SubPage>
}

function AlbumPage(props: PageProps) {
  const { state, setState, album } = props
  const [albumName, setAlbumName] = useState(state.albumName)
  const thumbnailInputRef = useRef<HTMLInputElement>(null)

  const driveDefaults = getDriveDefaultsFor(props.drive.drive_type)
  const currentThumbnail = album.thumbnail || driveDefaults.previewPicture

  function onThumbnailChange(e: any) {
    if (e.target.files.length > 0) {
      setState({
        ...state,
        albumThumbnail: e.target.files[0],
      })
    }
  }

  return <SubPage
    title='Dein erstes Album'
    description='Das wird angezeigt, wenn die Gäste deine Galerie öffnen'
    {...props}
  >
    <div className='flex items-center justify-center flex-col mb-3'>
      <label htmlFor='albumThumbnail'>
        Vorschaubild
      </label>
      <div className='w-[5rem] h-[5rem] mb-2 bg-cover bg-center rounded-full'
           style={{backgroundImage: `url(${state.albumThumbnail ? URL.createObjectURL(state.albumThumbnail) : currentThumbnail})`}}
           onClick={() => thumbnailInputRef.current!.click()}
      ></div>
      <input ref={thumbnailInputRef} accept='image/jpeg,image/png' type="file" style={{ display: 'none' }} onInput={onThumbnailChange} />
      <Button size='sm' color='primary' onClick={() => thumbnailInputRef.current!.click()}>Anpassen</Button>
    </div>
    <label htmlFor='albumName'>
      Titel
    </label>
    <Input name='albumName' type='text' value={albumName || ''} placeholder={album.title}
           onChange={e => updateProp(state, setState, 'albumName', setAlbumName, e.target.value)}/>
  </SubPage>
}

function AlbumDescriptionPage(props: PageProps) {
  const { state, setState, album } = props
  const [albumDescription, setAlbumDescription] = useState(state.albumDescription)

  return <SubPage
    title='Möchtest du deinen Gästen noch etwas sagen?'
    {...props}
  >
    <label htmlFor="albumDescription" className='mt-4'>
      Beschreibung
    </label>
    <Textarea name='albumDescription' value={albumDescription || ''} placeholder={album.description}
              onChange={e => updateProp(state, setState, 'albumDescription', setAlbumDescription, e.target.value)}/>
    <p className='mt-3 text-center'>
      Das wird unter dem Galerienamen angezeigt
    </p>
  </SubPage>
}

function AlbumThemePage(props: PageProps) {
  const { state, setState } = props
  const { setTheme } = useHeyTheme()

  function onThemeSelect(theme: ThemeDetails) {
    setTheme(
      theme.primary,
      theme.neutral,
      theme.content,
    )
    setState({
      ...state,
      colorPrimary: theme.primary,
      colorContent: theme.content,
      colorNeutral: theme.neutral,
    })
  }

  const themePreviews = defaultThemes.map((theme, i) => <ThemeColors key={i} theme={theme} onClick={() => onThemeSelect(theme)} />)

  return <SubPage
    title='Farben'
    description='Wie soll eure Galerie aussehen?'
    {...props}
  >
    <label>
      <div className='flex flex-col items-center gap-3'>
        <h3 className='text-xl font-semibold'>Beispielfarben auswählen</h3>
        <div className='flex flex-row gap-2'>
          {themePreviews}
        </div>
        <p className='text-center'>In den Einstellungen könnt ihr auch ganz eigene Farben verwenden.</p>
      </div>
    </label>
  </SubPage>
}

function AlbumPrivatePage(props: PageProps) {
  const { state, setState, album } = props
  const [isPrivate, setIsPrivate] = useState(state.albumIsPrivate || null)

  const checked = !(isPrivate == null ? album.is_private : isPrivate)

  return <SubPage
    title='Privatsphäre'
    description='Wer soll eure Bilder und Videos sehen können?'
    {...props}
  >
    <label>
      <div className='flex flex-row items-center gap-3'>
        <Checkbox color='primary' checked={checked}
                  onChange={() => updateProp(state, setState, 'albumIsPrivate', setIsPrivate, checked)}></Checkbox>
        Gäste können alle Bilder/Videos sehen
      </div>
      <p className='text-sm mt-1'>Wenn diese Option deaktiviert ist, können Gäste nur hochladen und ihre eigenen
        Bilder sehen.</p>
    </label>
  </SubPage>
}

interface PageProps {
  index?: number
  pageCount?: number
  state: OnboardingState
  drive: HeyDrive
  album: Album
  setState: (state: Partial<OnboardingState>) => void
  onNext?: () => void
  onSave?: () => void
  onBack?: () => void
  onClose?: () => void
  onLetsGo?: () => void
  isSaving: boolean
}

function SubPage({ title, description, children, onNext, onBack, onClose, onSave, onLetsGo, isSaving, index, pageCount }: { title: ReactNode, description?: ReactNode, children: ReactNode } & PageProps) {
  return <div className='h-[100vh] bg-secondary flex justify-center items-center flex-col'>
    { index != undefined && pageCount != undefined && <span className='text-xl font-serif'>
      Schritt {index + 1}/{pageCount}
    </span> }
    <div className='container mx-auto flex flex-col items-center py-4 h-full max-h-[40rem] max-w-[20rem]'>
      <h2 className='font-serif text-3xl text-center'>
        { title }
      </h2>
      { description && <p className='text-sm mt-2 mb-5 text-center'>{description}</p> }
      <div className='flex-1 py-5 px-5 flex flex-col w-full'>
        { children }
      </div>
      <div className='flex flex-col gap-2 mt-5 w-full'>
        { onLetsGo && <Button color='primary' onClick={onLetsGo} disabled={isSaving}>Los gehts!</Button> }
        { onSave && <Button color='primary' onClick={onSave} loading={isSaving} disabled={isSaving}>Speichern</Button> }
        { onNext && <Button color='primary' onClick={onNext}>Weiter</Button> }
        { onBack && <Button color='ghost' onClick={onBack} disabled={isSaving}>Zurück</Button>}
        { onClose && <Button color='ghost' onClick={onClose}>Abbrechen</Button> }
        {/*<Button color='ghost'>Überspringen</Button>*/}
      </div>
    </div>
  </div>
}

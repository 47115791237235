import { Album } from '@/models/album.ts'
import { QRCodeShape } from '@/components/QRCodeDesigner.tsx'

export enum HeyDriveType {
  WEDDING = 'wedding',
  BIRTHDAY = 'birthday',
  // PARTY = 'party',
  BABY_SHOWER = 'baby_shower',
  COMPANY = 'company',
  // NEW_YEAR = 'new_year',
  // CHRISTMAS = 'christmas',
  // VACATION = 'vacation',
}

export interface HeyDrive {
  id: string
  drive_type: HeyDriveType
  created_at: string
  title: string
  author: string
  albums: Array<Album>
  color_primary: string | null
  color_neutral: string | null
  color_content: string | null
  is_demo: boolean
  is_hide_yes: boolean
  is_show_onboarding: boolean
  is_ha: boolean
  qr_code_image: string | null
  qr_fg: string
  qr_bg: string
  qr_shape: QRCodeShape
}

import { Album } from '@/models/album.ts'
import React, { createContext, useContext } from 'react'
import { FilesContext, FilesProvider } from '@/contexts/filesContext.tsx'
import HeyGame from '@/models/heyGame.ts'
import { AlbumFile } from '@/models/albumFile.ts'


export const CurrentGameFilesContext = createContext<FilesContext | null>(null)

export function CurrentGameFilesProvider({ children, album, game }: { children: React.ReactNode, album: Album, game: HeyGame }) {
  function filesFiles(file: AlbumFile) {
    return file.game != null && file.game.id === game.id
  }

  return <FilesProvider albumId={album.id} filesFilter={{ game: game.id, all: '1' }} FilesContext={CurrentGameFilesContext} uploadedFilesFilter={filesFiles}>
    { children }
  </FilesProvider>
}

export function useCurrentGameFilesContext() {
  return useContext(CurrentGameFilesContext)
}

import { AlbumFile } from '@/models/albumFile.ts'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { HeyDriveType } from '@/models/drive.ts'

// export const demoFiles: Array<AlbumFile> = [
//   {id: '3VSkzpnh43armcfWydgwwe.JPG', game: null, file: `/demoX/img/3VSkzpnh43armcfWydgwwe.JPG`, thumbnail: `/demoX/thumbs/3VSkzpnh43armcfWydgwwe.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/3VSkzpnh43armcfWydgwwe.JPG`, is_demo: true},
//   {id: '3wUSysHn8rRSSiAKvFU2iE.JPG', game: null, file: `/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG`, thumbnail: `/demoX/thumbs/3wUSysHn8rRSSiAKvFU2iE.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG`, is_demo: true},
//   {id: '4xjm4qtr8QZc44ZinryEBs.JPG', game: null, file: `/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG`, thumbnail: `/demoX/thumbs/4xjm4qtr8QZc44ZinryEBs.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG`, is_demo: true},
//   {id: '8m2QyECa7Y7kk8MxNeWd6z.JPG', game: null, file: `/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG`, thumbnail: `/demoX/thumbs/8m2QyECa7Y7kk8MxNeWd6z.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG`, is_demo: true},
//   {id: '9Dr6aS5235h5Goa7UKsTXv.JPG', game: null, file: `/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG`, thumbnail: `/demoX/thumbs/9Dr6aS5235h5Goa7UKsTXv.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG`, is_demo: true},
//   {id: '9TPUgTdiV9HcTJF7gsjoXG.JPG', game: null, file: `/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG`, thumbnail: `/demoX/thumbs/9TPUgTdiV9HcTJF7gsjoXG.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG`, is_demo: true},
//   {id: '84FsSv3KP7t7jF5mwnAeS2.JPG', game: null, file: `/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG`, thumbnail: `/demoX/thumbs/84FsSv3KP7t7jF5mwnAeS2.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG`, is_demo: true},
//   {id: 'bHN9sqtjJ2a8Wa8kbfJ8d6.JPG', game: null, file: `/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, thumbnail: `/demoX/thumbs/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG`, is_demo: true},
//   {id: 'CFjKgEtGbkrZpehRsJJrvF.JPG', game: null, file: `/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG`, thumbnail: `/demoX/thumbs/CFjKgEtGbkrZpehRsJJrvF.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG`, is_demo: true},
//   {id: 'e6iyqHJDE8kaPAHjCEPFc2.JPG', game: null, file: `/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG`, thumbnail: `/demoX/thumbs/e6iyqHJDE8kaPAHjCEPFc2.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG`, is_demo: true},
//   {id: 'fHY7yC2Em2NeCPFkH9RDTA.JPG', game: null, file: `/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG`, thumbnail: `/demoX/thumbs/fHY7yC2Em2NeCPFkH9RDTA.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG`, is_demo: true},
//   {id: 'h3Jz5vzzTvgmwVyFSAfCDa.JPG', game: null, file: `/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, thumbnail: `/demoX/thumbs/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG`, is_demo: true},
//   {id: 'HLKDdXoTqdEEF6TrrcGStr.JPG', game: null, file: `/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG`, thumbnail: `/demoX/thumbs/HLKDdXoTqdEEF6TrrcGStr.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG`, is_demo: true},
//   {id: 'iXSH5Nv7fQeyvnF2mmQaWz.JPG', game: null, file: `/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, thumbnail: `/demoX/thumbs/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG`, is_demo: true},
//   {id: 'JDx7zf3xgycyPb3E3XD9zq.JPG', game: null, file: `/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG`, thumbnail: `/demoX/thumbs/JDx7zf3xgycyPb3E3XD9zq.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG`, is_demo: true},
//   {id: 'jHRZHhdPCNmUDAexNtw3q6.JPG', game: null, file: `/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG`, thumbnail: `/demoX/thumbs/jHRZHhdPCNmUDAexNtw3q6.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG`, is_demo: true},
//   {id: 'JJop8u2o4eQRmUtip4McUb.JPG', game: null, file: `/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG`, thumbnail: `/demoX/thumbs/JJop8u2o4eQRmUtip4McUb.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG`, is_demo: true},
//   {id: 'LgB4DchCZ9LBa5GYDakQq9.JPG', game: null, file: `/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG`, thumbnail: `/demoX/thumbs/LgB4DchCZ9LBa5GYDakQq9.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG`, is_demo: true},
//   {id: 'oPbmtpoTYnWZrGyovJz2Bd.JPG', game: null, file: `/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG`, thumbnail: `/demoX/thumbs/oPbmtpoTYnWZrGyovJz2Bd.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG`, is_demo: true},
//   {id: 'PAb8j5Zr2b8JkZHgyeabwa.JPG', game: null, file: `/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG`, thumbnail: `/demoX/thumbs/PAb8j5Zr2b8JkZHgyeabwa.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG`, is_demo: true},
//   {id: 'PRxJPRz9fhfW7QWBa8cqC.JPG', game: null, file: `/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG`, thumbnail: `/demoX/thumbs/PRxJPRz9fhfW7QWBa8cqC.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG`, is_demo: true},
//   {id: 'Qed7zTTYmtJdMYLgmy79BU.JPG', game: null, file: `/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG`, thumbnail: `/demoX/thumbs/Qed7zTTYmtJdMYLgmy79BU.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG`, is_demo: true},
//   {id: 'RyFdgXP6anS64BnnFrz8km.JPG', game: null, file: `/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG`, thumbnail: `/demoX/thumbs/RyFdgXP6anS64BnnFrz8km.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG`, is_demo: true},
//   {id: 'SNoYnrtzzHLPTemMHT9mZ3.JPG', game: null, file: `/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG`, thumbnail: `/demoX/thumbs/SNoYnrtzzHLPTemMHT9mZ3.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG`, is_demo: true},
//   {id: 'UVtvD4PzAKRnXNYRSJEHbL.JPG', game: null, file: `/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG`, thumbnail: `/demoX/thumbs/UVtvD4PzAKRnXNYRSJEHbL.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG`, is_demo: true},
//   {id: 'X3x8teKXi3RRGnie7nFCZV.JPG', game: null, file: `/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG`, thumbnail: `/demoX/thumbs/X3x8teKXi3RRGnie7nFCZV.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG`, is_demo: true},
//   {id: 'yZovTZaNdh5Dut29hSNYj.JPG', game: null, file: `/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG`, thumbnail: `/demoX/thumbs/yZovTZaNdh5Dut29hSNYj.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG`, is_demo: true},
//   {id: 'ZA67EL3C2XBoKym6DrozfL.JPG', game: null, file: `/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG`, thumbnail: `/demoX/thumbs/ZA67EL3C2XBoKym6DrozfL.JPG`, author: '-1', file_type: AlbumFileType.IMAGE, content_type: 'image/jpeg', detail: `/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG`, is_demo: true},
// ]

const weddingVideo: AlbumFile = {
  id: 'demo-video',
  author: '-1',
  game: null,
  file: '/demoX/vid/demo-video-compressed.mp4',
  detail: '/demoX/vid/demo-video-compressed.mp4',
  thumbnail: '/demoX/thumbs/thumbnail-demo-video.jpg',
  file_type: AlbumFileType.VIDEO,
  content_type: 'video/mp4',
  author_display_name: 'Demo',
  is_demo: true,
  video_duration_seconds: 29.2,
  created_at: '',
  file_creation: null,
}

export const weddingDemoFiles: Array<AlbumFile> = [
  {
    id: '3joGCtDec97dC4GqgAWCFW',
    author: '-1',
    game: null,
    file: '/demoX/img/3VSkzpnh43armcfWydgwwe.JPG',
    detail: '/demoX/img/3VSkzpnh43armcfWydgwwe.JPG',
    thumbnail: '/demoX/thumbs/SHT3NHi2bUT8Xv2FBiLvPE.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'U4fZcYcuRHk4focgRaguYR',
    author: '-1',
    game: null,
    file: '/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG',
    detail: '/demoX/img/JJop8u2o4eQRmUtip4McUb.JPG',
    thumbnail: '/demoX/thumbs/ch9jxCg2BHHvqbtXXptXv9.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'jNrXCjbxC8zp9Hd8xJGQwy',
    author: '-1',
    game: null,
    file: '/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG',
    detail: '/demoX/img/9TPUgTdiV9HcTJF7gsjoXG.JPG',
    thumbnail: '/demoX/thumbs/YA8YzFe8PsWk4YEXc7AboV.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'fSp2MUGoSmq2wbpoPMdWDW',
    author: '-1',
    game: null,
    file: '/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG',
    detail: '/demoX/img/X3x8teKXi3RRGnie7nFCZV.JPG',
    thumbnail: '/demoX/thumbs/bT4Ae2MgSwqVtU6ot5qqhr.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'TNGShVYjJXrCvPNLBTjoWk',
    author: '-1',
    game: null,
    file: '/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG',
    detail: '/demoX/img/4xjm4qtr8QZc44ZinryEBs.JPG',
    thumbnail: '/demoX/thumbs/jUPxMLihGKkaBVBYp26gKc.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'GoX2x7YJkYCmWURR8zUUz5',
    author: '-1',
    game: null,
    file: '/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG',
    detail: '/demoX/img/iXSH5Nv7fQeyvnF2mmQaWz.JPG',
    thumbnail: '/demoX/thumbs/Ue98qqvi7Xw7qESLNeNiUs.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'GTMFn73McopppXeySfY8MF',
    author: '-1',
    game: null,
    file: '/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG',
    detail: '/demoX/img/JDx7zf3xgycyPb3E3XD9zq.JPG',
    thumbnail: '/demoX/thumbs/ZRmX84BHYRPxjtTqwdZx34.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  weddingVideo,
  {
    id: 'i2f3WM7dBdt94tBtKVuqs4',
    author: '-1',
    game: null,
    file: '/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG',
    detail: '/demoX/img/84FsSv3KP7t7jF5mwnAeS2.JPG',
    thumbnail: '/demoX/thumbs/T4wVQUV64j3t5m4jyHXZUc.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'AK8ubB6HPYKDaPGBU6qsDA',
    author: '-1',
    game: null,
    file: '/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG',
    detail: '/demoX/img/fHY7yC2Em2NeCPFkH9RDTA.JPG',
    thumbnail: '/demoX/thumbs/CfJJRgC5zbcdoiTvXgayyD.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: '99KUhnjtKPVtktkdeUMtwi',
    author: '-1',
    game: null,
    file: '/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG',
    detail: '/demoX/img/RyFdgXP6anS64BnnFrz8km.JPG',
    thumbnail: '/demoX/thumbs/SAcVUDWMRjtrPSohrKwyfo.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'BtySkBnPxL3KtCh59eyFPH',
    author: '-1',
    game: null,
    file: '/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG',
    detail: '/demoX/img/HLKDdXoTqdEEF6TrrcGStr.JPG',
    thumbnail: '/demoX/thumbs/ckt3Pj2ZEuriKmxrK98Mbr.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'FAzzzQZAewUudo2Qx3EfLK',
    author: '-1',
    game: null,
    file: '/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG',
    detail: '/demoX/img/Qed7zTTYmtJdMYLgmy79BU.JPG',
    thumbnail: '/demoX/thumbs/nJogEMY4j82v9Um2FDmEvq.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: '4aZGKxwdztbA6tdgcn5p4u',
    author: '-1',
    game: null,
    file: '/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG',
    detail: '/demoX/img/ZA67EL3C2XBoKym6DrozfL.JPG',
    thumbnail: '/demoX/thumbs/MNd8gDkjZUkChjaZxMcDzY.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'WFQ4wiREchYKJBMKzHp4Jz',
    author: '-1',
    game: null,
    file: '/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG',
    detail: '/demoX/img/oPbmtpoTYnWZrGyovJz2Bd.JPG',
    thumbnail: '/demoX/thumbs/53GaC2UKffijAe9inadSRw.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'gyYVrGFt86Q7z37UaFk7Sz',
    author: '-1',
    game: null,
    file: '/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG',
    detail: '/demoX/img/8m2QyECa7Y7kk8MxNeWd6z.JPG',
    thumbnail: '/demoX/thumbs/GqmyWF2GXoucHJ38J7x7Jc.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'hVofzcHS8NEK7vgq3oUwbU',
    author: '-1',
    game: null,
    file: '/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG',
    detail: '/demoX/img/UVtvD4PzAKRnXNYRSJEHbL.JPG',
    thumbnail: '/demoX/thumbs/5DjHgvCayM9zPwcjVAdmJw.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'iuG4foDyKqnBfag4ZpJxAC',
    author: '-1',
    game: null,
    file: '/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG',
    detail: '/demoX/img/LgB4DchCZ9LBa5GYDakQq9.JPG',
    thumbnail: '/demoX/thumbs/QG7aXHRZVXw4ApzfH7v7Y.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'GzYsrusfY7eHx5UD8ZnBe9',
    author: '-1',
    game: null,
    file: '/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG',
    detail: '/demoX/img/3wUSysHn8rRSSiAKvFU2iE.JPG',
    thumbnail: '/demoX/thumbs/8oAenWLBPx7Mt8CFRLNNQp.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'k7FiBz8XNGjkSqmkEMmZMm',
    author: '-1',
    game: null,
    file: '/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG',
    detail: '/demoX/img/PAb8j5Zr2b8JkZHgyeabwa.JPG',
    thumbnail: '/demoX/thumbs/KuXApaApUfdgGQNUjeXf2x.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: '5vB98tc9Y5ota9mr2FFnPU',
    author: '-1',
    game: null,
    file: '/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG',
    detail: '/demoX/img/CFjKgEtGbkrZpehRsJJrvF.JPG',
    thumbnail: '/demoX/thumbs/fQsEMnsWvhxCoZoWXvShUE.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'LTyh42uVAu7WxvUqdra7h9',
    author: '-1',
    game: null,
    file: '/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG',
    detail: '/demoX/img/e6iyqHJDE8kaPAHjCEPFc2.JPG',
    thumbnail: '/demoX/thumbs/gLyD8SRxaVSB69GobDyvRA.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'dfYQvg4W3KQfu4ChUUKaPH',
    author: '-1',
    game: null,
    file: '/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG',
    detail: '/demoX/img/PRxJPRz9fhfW7QWBa8cqC.JPG',
    thumbnail: '/demoX/thumbs/dpjTgjgbxu88DzNmQTbbbg.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'ffvPhM5reBDnTwLpU9f56V',
    author: '-1',
    game: null,
    file: '/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG',
    detail: '/demoX/img/jHRZHhdPCNmUDAexNtw3q6.JPG',
    thumbnail: '/demoX/thumbs/jAyxQ7Sq49xW2ycUvA3Dqe.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'XrFTspEbTaFgqYmbsjHqK9',
    author: '-1',
    game: null,
    file: '/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG',
    detail: '/demoX/img/h3Jz5vzzTvgmwVyFSAfCDa.JPG',
    thumbnail: '/demoX/thumbs/C3QYMKkTMVNVEEQ6Q3Woe6.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: '4SLSjnvQb5fQNEB9R5xB9K',
    author: '-1',
    game: null,
    file: '/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG',
    detail: '/demoX/img/bHN9sqtjJ2a8Wa8kbfJ8d6.JPG',
    thumbnail: '/demoX/thumbs/Dz4v8bsnqVGXsEbrY4idR5.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'QPmkhYhzXu2SuCn3tJHwsV',
    author: '-1',
    game: null,
    file: '/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG',
    detail: '/demoX/img/9Dr6aS5235h5Goa7UKsTXv.JPG',
    thumbnail: '/demoX/thumbs/KxqAN7PVDpoRgSZxxS9gEj.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'LmnUAXug8A7Mgz6EfdrkoT',
    author: '-1',
    game: null,
    file: '/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG',
    detail: '/demoX/img/yZovTZaNdh5Dut29hSNYj.JPG',
    thumbnail: '/demoX/thumbs/kJvFdbKN8GxSHfFnY5d9yZ.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,
  {
    id: 'e9wDK72HxCVtB5xRTqQa44',
    author: '-1',
    game: null,
    file: '/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG',
    detail: '/demoX/img/SNoYnrtzzHLPTemMHT9mZ3.JPG',
    thumbnail: '/demoX/thumbs/FfHRMNigKmEN7kwgfhJCV8.JPG',
    content_type: 'image/jpeg',
    is_demo: true,
    file_type: AlbumFileType.IMAGE,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
  } ,


]

const birthdayFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/what-is-a-color-party-article-hero-image.jpg',
    detail: '/demoX/birthday/img/what-is-a-color-party-article-hero-image.jpg',
    thumbnail: '/demoX/birthday/thumbs/what-is-a-color-party-article-hero-image Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/best-party-planner.jpg',
    detail: '/demoX/birthday/img/best-party-planner.jpg',
    thumbnail: '/demoX/birthday/thumbs/best-party-planner Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/black-and-gold.jpg',
    detail: '/demoX/birthday/img/black-and-gold.jpg',
    thumbnail: '/demoX/birthday/thumbs/black-and-gold Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/Cocktailparty.jpg',
    detail: '/demoX/birthday/img/Cocktailparty.jpg',
    thumbnail: '/demoX/birthday/thumbs/Cocktailparty Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/Fotolia_86060274-Astarot-Fotolia.com_M.jpg',
    detail: '/demoX/birthday/img/Fotolia_86060274-Astarot-Fotolia.com_M.jpg',
    thumbnail: '/demoX/birthday/thumbs/Fotolia_86060274-Astarot-Fotolia.com_M Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/geburtstagsparty-motto-casino.jpg',
    detail: '/demoX/birthday/img/geburtstagsparty-motto-casino.jpg',
    thumbnail: '/demoX/birthday/thumbs/geburtstagsparty-motto-casino Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/gettyimages-1187652065_slide-49ce797b39c56339d60054f04aa1a3ea06c68cc5.jpg',
    detail: '/demoX/birthday/img/gettyimages-1187652065_slide-49ce797b39c56339d60054f04aa1a3ea06c68cc5.jpg',
    thumbnail: '/demoX/birthday/thumbs/gettyimages-1187652065_slide-49ce797b39c56339d60054f04aa1a3ea06c68cc5 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/group-cheerful-friends-celebrating-birthday.jpg',
    detail: '/demoX/birthday/img/group-cheerful-friends-celebrating-birthday.jpg',
    thumbnail: '/demoX/birthday/thumbs/group-cheerful-friends-celebrating-birthday Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/istockphoto-497317250-612x612.jpg',
    detail: '/demoX/birthday/img/istockphoto-497317250-612x612.jpg',
    thumbnail: '/demoX/birthday/thumbs/istockphoto-497317250-612x612 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/istockphoto-871544320-612x612.jpg',
    detail: '/demoX/birthday/img/istockphoto-871544320-612x612.jpg',
    thumbnail: '/demoX/birthday/thumbs/istockphoto-871544320-612x612 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/istockphoto-1418413821-612x612.jpg',
    detail: '/demoX/birthday/img/istockphoto-1418413821-612x612.jpg',
    thumbnail: '/demoX/birthday/thumbs/istockphoto-1418413821-612x612 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/joyful-friends-with-birthday-cake.jpg',
    detail: '/demoX/birthday/img/joyful-friends-with-birthday-cake.jpg',
    thumbnail: '/demoX/birthday/thumbs/joyful-friends-with-birthday-cake Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/party-alkohol-100_v-variantBig16x9_wm-true_zc-ecbbafc6.jpg',
    detail: '/demoX/birthday/img/party-alkohol-100_v-variantBig16x9_wm-true_zc-ecbbafc6.jpg',
    thumbnail: '/demoX/birthday/thumbs/party-alkohol-100_v-variantBig16x9_wm-true_zc-ecbbafc6 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/Party-mit-Motto.jpg',
    detail: '/demoX/birthday/img/Party-mit-Motto.jpg',
    thumbnail: '/demoX/birthday/thumbs/Party-mit-Motto Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '15',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/shutterstock_155193032.jpg',
    detail: '/demoX/birthday/img/shutterstock_155193032.jpg',
    thumbnail: '/demoX/birthday/thumbs/shutterstock_155193032 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '16',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/tauzher-party.jpg',
    detail: '/demoX/birthday/img/tauzher-party.jpg',
    thumbnail: '/demoX/birthday/thumbs/tauzher-party Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '17',
    author: '-1',
    game: null,
    file: '/demoX/birthday/img/Tipp-gelungene-geburtstagsparty.jpg',
    detail: '/demoX/birthday/img/Tipp-gelungene-geburtstagsparty.jpg',
    thumbnail: '/demoX/birthday/thumbs/Tipp-gelungene-geburtstagsparty Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  }
]

const babyShowerFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/was-ist-eine-babyparty-foto-iris-merola.jpg',
    detail: '/demoX/baby_shower/img/was-ist-eine-babyparty-foto-iris-merola.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/was-ist-eine-babyparty-foto-iris-merola Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '2',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/40_Fun_Baby_Shower_Games_720x432 Large.jpeg',
    detail: '/demoX/baby_shower/img/40_Fun_Baby_Shower_Games_720x432 Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/40_Fun_Baby_Shower_Games_720x432 Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/989_25_Exciting_Prize_Ideas_For_Baby_Shower_Game_Winners_Hero.jpeg',
    detail: '/demoX/baby_shower/img/989_25_Exciting_Prize_Ideas_For_Baby_Shower_Game_Winners_Hero.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/989_25_Exciting_Prize_Ideas_For_Baby_Shower_Game_Winners_Hero Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/34313355-viele-frauen-feiern-eine-schwangere-auf-einer-babyparty-3mfe.jpg',
    detail: '/demoX/baby_shower/img/34313355-viele-frauen-feiern-eine-schwangere-auf-einer-babyparty-3mfe.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/34313355-viele-frauen-feiern-eine-schwangere-auf-einer-babyparty-3mfe Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/8891606549175795a77eeb01ab241c29.jpg',
    detail: '/demoX/baby_shower/img/8891606549175795a77eeb01ab241c29.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/8891606549175795a77eeb01ab241c29 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/baby_shower_3 Large.jpeg',
    detail: '/demoX/baby_shower/img/baby_shower_3 Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/baby_shower_3 Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/baby-shower-definition-organisation-deco-et-activites-pour-une-fete-originale.jpeg',
    detail: '/demoX/baby_shower/img/baby-shower-definition-organisation-deco-et-activites-pour-une-fete-originale.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/baby-shower-definition-organisation-deco-et-activites-pour-une-fete-originale Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/baby-showerparty-babyparty-image Large.jpeg',
    detail: '/demoX/baby_shower/img/baby-showerparty-babyparty-image Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/baby-showerparty-babyparty-image Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/Babyparty-Gaeste.jpg',
    detail: '/demoX/baby_shower/img/Babyparty-Gaeste.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/Babyparty-Gaeste Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/Babyparty.jpg',
    detail: '/demoX/baby_shower/img/Babyparty.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/Babyparty Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/babyshower-kategorie-text-media-header.jpg',
    detail: '/demoX/baby_shower/img/babyshower-kategorie-text-media-header.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/babyshower-kategorie-text-media-header Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/babyshower-organisieren-text-media1.jpg',
    detail: '/demoX/baby_shower/img/babyshower-organisieren-text-media1.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/babyshower-organisieren-text-media1 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/babyshower.jpg',
    detail: '/demoX/baby_shower/img/babyshower.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/babyshower Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/BabyShower6-1280x934 Large.jpeg',
    detail: '/demoX/baby_shower/img/BabyShower6-1280x934 Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/BabyShower6-1280x934 Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '15',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/BabyshowerBasteln01.jpg',
    detail: '/demoX/baby_shower/img/BabyshowerBasteln01.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/BabyshowerBasteln01 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '16',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/BabyshowerGeschenkeZurGeburt.jpg',
    detail: '/demoX/baby_shower/img/BabyshowerGeschenkeZurGeburt.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/BabyshowerGeschenkeZurGeburt Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '17',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/blog_250_12.jpg',
    detail: '/demoX/baby_shower/img/blog_250_12.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/blog_250_12 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '18',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/csm_Babyparty-header-neu_c3a374e8ca.jpg',
    detail: '/demoX/baby_shower/img/csm_Babyparty-header-neu_c3a374e8ca.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/csm_Babyparty-header-neu_c3a374e8ca Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '19',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/gender-reveal-party-aufmacher Large.jpeg',
    detail: '/demoX/baby_shower/img/gender-reveal-party-aufmacher Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/gender-reveal-party-aufmacher Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '20',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/gettyimages-1438199573.jpg',
    detail: '/demoX/baby_shower/img/gettyimages-1438199573.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/gettyimages-1438199573 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '21',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/image.jpg',
    detail: '/demoX/baby_shower/img/image.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/image Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '22',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/IMG_9789-e1531234738107 Large.jpeg',
    detail: '/demoX/baby_shower/img/IMG_9789-e1531234738107 Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/IMG_9789-e1531234738107 Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '23',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/Magazin_Baby_Shower_Image2.jpg',
    detail: '/demoX/baby_shower/img/Magazin_Baby_Shower_Image2.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/Magazin_Baby_Shower_Image2 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '24',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/pexels-pavel-danilyuk-7180794-1000x625.jpg',
    detail: '/demoX/baby_shower/img/pexels-pavel-danilyuk-7180794-1000x625.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/pexels-pavel-danilyuk-7180794-1000x625 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '25',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/qui-organise-baby-shower-89_480x480 Large.jpeg',
    detail: '/demoX/baby_shower/img/qui-organise-baby-shower-89_480x480 Large.jpeg',
    thumbnail: '/demoX/baby_shower/thumbs/qui-organise-baby-shower-89_480x480 Large Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '26',
    author: '-1',
    game: null,
    file: '/demoX/baby_shower/img/stylingliebe-lifestyleblogger-fashionblog-muenchen-styleblog-munich-blogger-deutschland-fashionblogger-bloggerdeut.jpg',
    detail: '/demoX/baby_shower/img/stylingliebe-lifestyleblogger-fashionblog-muenchen-styleblog-munich-blogger-deutschland-fashionblogger-bloggerdeut.jpg',
    thumbnail: '/demoX/baby_shower/thumbs/stylingliebe-lifestyleblogger-fashionblog-muenchen-styleblog-munich-blogger-deutschland-fashionblogger-bloggerdeut Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  }
]

const companyFiles: Array<AlbumFile> = [
  {
    id: '1',
    author: '-1',
    game: null,
    file: '/demoX/company/img/firmenfeier3.jpg',
    detail: '/demoX/company/img/firmenfeier3.jpg',
    thumbnail: '/demoX/company/thumbs/firmenfeier3 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '3',
    author: '-1',
    game: null,
    file: '/demoX/company/img/19-07-2021-IW-002-TW-TW-Langeweile-auf-der-Firmenfeier-vermeiden.jpg',
    detail: '/demoX/company/img/19-07-2021-IW-002-TW-TW-Langeweile-auf-der-Firmenfeier-vermeiden.jpg',
    thumbnail: '/demoX/company/thumbs/19-07-2021-IW-002-TW-TW-Langeweile-auf-der-Firmenfeier-vermeiden Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '4',
    author: '-1',
    game: null,
    file: '/demoX/company/img/37219973_L.jpg',
    detail: '/demoX/company/img/37219973_L.jpg',
    thumbnail: '/demoX/company/thumbs/37219973_L Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '5',
    author: '-1',
    game: null,
    file: '/demoX/company/img/79437423-freunde-offnen-flasche-champagner-wie-sie-auf-einer-party-zusammen-feiern.jpg',
    detail: '/demoX/company/img/79437423-freunde-offnen-flasche-champagner-wie-sie-auf-einer-party-zusammen-feiern.jpg',
    thumbnail: '/demoX/company/thumbs/79437423-freunde-offnen-flasche-champagner-wie-sie-auf-einer-party-zusammen-feiern Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '6',
    author: '-1',
    game: null,
    file: '/demoX/company/img/6514273582982f3bca1559ac_6495708a69906da7f0e243d1_office-party-ideas.jpeg',
    detail: '/demoX/company/img/6514273582982f3bca1559ac_6495708a69906da7f0e243d1_office-party-ideas.jpeg',
    thumbnail: '/demoX/company/thumbs/6514273582982f3bca1559ac_6495708a69906da7f0e243d1_office-party-ideas Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '7',
    author: '-1',
    game: null,
    file: '/demoX/company/img/AdobeStock_Monkey_Business-1080x675.jpeg',
    detail: '/demoX/company/img/AdobeStock_Monkey_Business-1080x675.jpeg',
    thumbnail: '/demoX/company/thumbs/AdobeStock_Monkey_Business-1080x675 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '8',
    author: '-1',
    game: null,
    file: '/demoX/company/img/betriebsfeier.jpg',
    detail: '/demoX/company/img/betriebsfeier.jpg',
    thumbnail: '/demoX/company/thumbs/betriebsfeier Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '9',
    author: '-1',
    game: null,
    file: '/demoX/company/img/csm_AdobeStock_124971443_d996429627.jpg',
    detail: '/demoX/company/img/csm_AdobeStock_124971443_d996429627.jpg',
    thumbnail: '/demoX/company/thumbs/csm_AdobeStock_124971443_d996429627 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '10',
    author: '-1',
    game: null,
    file: '/demoX/company/img/csm_entwurf_betriebsfest_18_04_18f8eff8cf.jpg',
    detail: '/demoX/company/img/csm_entwurf_betriebsfest_18_04_18f8eff8cf.jpg',
    thumbnail: '/demoX/company/thumbs/csm_entwurf_betriebsfest_18_04_18f8eff8cf Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '11',
    author: '-1',
    game: null,
    file: '/demoX/company/img/csm_fa56ba0de9254768a51af8fd4305bf58_ea9fc34c33.jpg',
    detail: '/demoX/company/img/csm_fa56ba0de9254768a51af8fd4305bf58_ea9fc34c33.jpg',
    thumbnail: '/demoX/company/thumbs/csm_fa56ba0de9254768a51af8fd4305bf58_ea9fc34c33 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '12',
    author: '-1',
    game: null,
    file: '/demoX/company/img/feierbetrieb.jpg',
    detail: '/demoX/company/img/feierbetrieb.jpg',
    thumbnail: '/demoX/company/thumbs/feierbetrieb Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '13',
    author: '-1',
    game: null,
    file: '/demoX/company/img/firmenfeier-620x415.jpg',
    detail: '/demoX/company/img/firmenfeier-620x415.jpg',
    thumbnail: '/demoX/company/thumbs/firmenfeier-620x415 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '14',
    author: '-1',
    game: null,
    file: '/demoX/company/img/Firmenfeier.jpg',
    detail: '/demoX/company/img/Firmenfeier.jpg',
    thumbnail: '/demoX/company/thumbs/Firmenfeier Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '15',
    author: '-1',
    game: null,
    file: '/demoX/company/img/foto.jpg',
    detail: '/demoX/company/img/foto.jpg',
    thumbnail: '/demoX/company/thumbs/foto.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '16',
    author: '-1',
    game: null,
    file: '/demoX/company/img/gruppe-mensche.jpg',
    detail: '/demoX/company/img/gruppe-mensche.jpg',
    thumbnail: '/demoX/company/thumbs/gruppe-mensche Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '17',
    author: '-1',
    game: null,
    file: '/demoX/company/img/gruppe.jpg',
    detail: '/demoX/company/img/gruppe.jpg',
    thumbnail: '/demoX/company/thumbs/gruppe Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '18',
    author: '-1',
    game: null,
    file: '/demoX/company/img/istockphoto-1152502772-612x612.jpg',
    detail: '/demoX/company/img/istockphoto-1152502772-612x612.jpg',
    thumbnail: '/demoX/company/thumbs/istockphoto-1152502772-612x612 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  } ,
  {
    id: '19',
    author: '-1',
    game: null,
    file: '/demoX/company/img/istockphoto-1285453567-612x612.jpg',
    detail: '/demoX/company/img/istockphoto-1285453567-612x612.jpg',
    thumbnail: '/demoX/company/thumbs/istockphoto-1285453567-612x612 Small.jpeg',
    content_type: 'image/jpeg',
    is_demo: true,
    author_display_name: 'Demo',
    created_at: '',
    file_creation: null,
    file_type: AlbumFileType.IMAGE
  }
]

const demoFilesForType: Record<HeyDriveType, Array<AlbumFile>> = {
  [HeyDriveType.WEDDING]: weddingDemoFiles,
  [HeyDriveType.BIRTHDAY]: birthdayFiles,
  [HeyDriveType.BABY_SHOWER]: babyShowerFiles,
  [HeyDriveType.COMPANY]: companyFiles,
}

export function getDemoFilesForDriveType(driveType: HeyDriveType) {
  return demoFilesForType[driveType]
}

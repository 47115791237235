import { useRef, useState } from 'react'
import { ChromePicker } from 'react-color'
import { Button, Checkbox, Modal } from 'react-daisyui'
import { t } from 'i18next'

export enum QRCodeShape {
  Square = 'square',
  Rounded = 'round',
}

export default function QRCodeDesigner(
  {
    svgBannerUrl,
    shape,
    setShape,
    shapes,
    fg,
    setFg,
    bg,
    setBg,
  }: {
  svgBannerUrl: string,
  shape: QRCodeShape,
  setShape: (shape: QRCodeShape) => void,
  shapes: Record<QRCodeShape, string>,
  fg: string,
  setFg: (fg: string) => void,
  bg: string,
  setBg: (bg: string) => void,
}) {
  const modalRef = useRef<HTMLDialogElement>(null)
  const [currentlyPickung, setCurrentlyPickung] = useState<null | 'fg' | 'bg'>(null)
  const currentModalData = currentlyPickung == null ? null : currentlyPickung === 'fg' ? {
      display: t('common.qrCodeColor'),
      setter: setFg,
      color: fg,
    } : {
    display: t('common.backgroundColor'),
    setter: setBg,
    color: bg,
  }

  console.log('shape', shape)

  // const shapeSelections: Array<{ shape: QRCodeShape, name: string }> = [
  //   {shape: QRCodeShape.Rounded, name: 'Rund'},
  //   {shape: QRCodeShape.Square, name: 'Eckig'},
  // ]

  function onFgClick() {
    setCurrentlyPickung('fg')
    modalRef.current!.showModal()
  }

  function onBgClick() {
    setCurrentlyPickung('bg')
    modalRef.current!.showModal()
  }

  return <div className='w-full max-w-full'>
    <Modal ref={modalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {currentModalData?.display}
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col items-center'>
          {currentModalData && <div className='flex flex-col gap-3 items-center'>
            <QRCode svgCodeUrl={shapes[shape]} svgBannerUrl={svgBannerUrl} fg={fg} bg={bg}/>
            <ChromePicker
                disableAlpha
                // @ts-ignore
                color={currentModalData.color}
                onChange={(c) => currentModalData!.setter(c.hex)}
              />
            </div>
          }
          <Button className='mt-5' color='primary' onClick={() => modalRef.current?.close()}>{t('common.done')}</Button>
        </div>
      </Modal.Body>
    </Modal>
    <div className='flex flex-row'>
      <QRCode svgCodeUrl={shapes[shape]} svgBannerUrl={svgBannerUrl} fg={fg} bg={bg}/>

      <div className='flex flex-col gap-3 px-2 justify-center'>
        <div className='relative flex flex-col'>
          <span className='font-bold'>{t('common.qrCodeColor')}</span>
          <div className='flex flex-row gap-0 cursor-pointer' onClick={onFgClick}>
            <div className='h-[1.5rem] w-[1.5rem] me-1' style={{backgroundColor: fg}} onClick={onFgClick}></div>
            {/*<input className='min-h-full ps-2 max-w-[6rem] border-0' placeholder='#FFFFFF' value={fg}*/}
            {/*       onChange={e => setFg(e.target.value)}/>*/}
            <span className='ms-2'>🎨 <span className='underline'>{t('common.adapt')}</span></span>

          </div>
        </div>
        <div className='relative flex flex-col gap-1'>
          <span className='font-bold'>{t('common.backgroundColor')}</span>
          <div className='flex flex-row gap-0 cursor-pointer' onClick={onBgClick}>
            <div className='h-[1.5rem] w-[1.5rem] me-1' style={{backgroundColor: bg}}></div>
            <span className='ms-2'>🎨 <span className='underline'>{t('common.adapt')}</span></span>
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <label className='flex flex-row gap-1 select-none'>
            <Checkbox color='primary' checked={shape === QRCodeShape.Rounded} onClick={() => setShape(shape === QRCodeShape.Rounded ? QRCodeShape.Square : QRCodeShape.Rounded)} />
            {t('common.roundedCornersQuestion')}
            {/*{shapeSelections.map(({shape: _shape, name}) => <button*/}
            {/*  key={_shape}*/}
            {/*  className={'btn btn-sm ' + (shape === _shape ? 'btn-primary' : 'btn-neutral')}*/}
            {/*  onClick={() => setShape(_shape)}>{name}</button>)}*/}
          </label>
        </div>
      </div>
    </div>
    <div className='flex flex-col gap-3'>
      {/*<div className='flex flex-col gap-1'>*/}
      {/*  <span>Form</span>*/}
      {/*  <div className='flex flex-row gap-0'>*/}
      {/*    {shapeSelections.map(({shape: _shape, name}) => <button*/}
      {/*      key={_shape}*/}
      {/*      className={'btn ' + (shape === _shape ? 'btn-primary' : 'btn-neutral')}*/}
      {/*      onClick={() => setShape(_shape)}>{name}</button>)}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  </div>
}

function QRCode({bg, fg, svgBannerUrl, svgCodeUrl}: {
  bg?: string,
  fg?: string,
  svgBannerUrl: string,
  svgCodeUrl: string
}) {
  return <div className='relative w-[10rem] max-w-[10rem] h-[10rem] aspect-square' style={{backgroundColor: bg || 'white'}}>
    <div
      className='absolute w-full aspect-square bg-center bg-contain flex justify-center items-center'
      style={{
        maskImage: `url(${svgCodeUrl})`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskSize: 'contain',
        backgroundColor: fg || 'black'
      }}>
    </div>
    <div className='absolute w-full aspect-square flex justify-center items-center'>
      <div className='absolute w-full aspect-square flex justify-center items-center'>
        <div className='w-[65%]'
             style={{
               backgroundColor: bg || 'white'
             }}>
          <object className='w-full text-primary pointer-events-none opacity-0 ' data={svgBannerUrl}/>
        </div>
      </div>
      <div className='w-[65%] text-primary pointer-events-none'
           style={{
             maskImage: `url(${svgBannerUrl})`,
             maskRepeat: 'no-repeat',
             maskPosition: 'center',
             maskSize: 'contain',
             backgroundColor: fg || 'black'
           }}>
        <object className='w-full text-primary pointer-events-none opacity-0 ' data={svgBannerUrl}/>
      </div>
    </div>
  </div>
}

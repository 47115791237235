import { create } from 'zustand'
import { produce } from 'immer'
import { Album } from '@/models/album'

interface UseAlbumState {
  albums: Record<string, Album>
}

interface UseAlbumActions {
  setAlbum: (album: Album) => void
}

export const useAlbums = create<UseAlbumState & UseAlbumActions>((set) => ({
  albums: {},
  setAlbum(album) {
    set(produce(state => {
      state.albums[album.id] = album
    }))
  },
}))

import { isStatusFinalState, UploadFileStatus, useUploadQueue } from '@/stores/useUploadQueue.ts'
import { Loading } from 'react-daisyui'

export default function UploadProgress() {
  const { uploadQueue } = useUploadQueue()
  const isUploading = uploadQueue.some(file => !isStatusFinalState(file.status))
  // const isUploading = true

  if (!isUploading) {
    return null
  }

  const finishedCount = uploadQueue.filter(file => isStatusFinalState(file.status))
  const currentlyUploading = uploadQueue.find(file => file.status === UploadFileStatus.Uploading)
  const percentageFinished = currentlyUploading && currentlyUploading.bytesUploaded != null ? currentlyUploading.bytesUploaded / currentlyUploading.file.size * 100 : null
  // const percentageFinished = 23

  return <>
    { isUploading && <div className='bg-secondary rounded-xl relative bottom-0'>
      <div className='flex flex-row items-center h-[2.5rem] px-4 gap-2 justify-center '>
        { (!currentlyUploading || percentageFinished == null) && <Loading color='primary' /> }
        { percentageFinished != null  &&
          <div className='text-primary font-black'>
            { Math.floor(percentageFinished) }%
          </div>
         }
        <span className='font-semibold'>{ finishedCount.length }/{ uploadQueue.length } Datei(en) hochgeladen</span>
        {/*{*/}
        {/*  currentlyUploading && <div className='inline-flex flex-row gap-3'>*/}
        {/*    <div className='max-w-[4rem] whitespace-nowrap overflow-hidden text-ellipsis'>{ currentlyUploading.file.name }</div>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </div> }
  </>
}

import { useFilesContext } from '@/contexts/filesContext.tsx'
import { Button, Checkbox, Range } from 'react-daisyui'
import { useEffect, useMemo, useState } from 'react'
import { useClient } from '@/stores/useClient.ts'
import { useQuery } from '@tanstack/react-query'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { AlbumFile, prepareForLightbox } from '@/models/albumFile.ts'
import { getDemoFilesForDriveType } from '@/controller/demo.ts'
import Lightbox from 'yet-another-react-lightbox'
import { Fullscreen, Slideshow } from 'yet-another-react-lightbox/plugins'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import DriveHeader from '@/components/DriveHeader.tsx'
import { useNavigate } from 'react-router-dom'
import { Album } from '@/models/album.ts'
import { useDrives } from '@/stores/useDrives.ts'
import { t } from 'i18next'

export default function LiveshowPage() {
  const { currentDrive } = useDrives()
  const { currentAlbum: album } = useFilesContext()!
  const { getLiveshow } = useClient()
  const [renderAlbumFiles, setRenderAlbumFiles] = useState<Array<AlbumFile>>([])
  const [index, setIndex] = useState<number | null>(null)
  const [slideDelay, setSlideDelay] = useState(3)
  const [isRandomOrder, setIsRandomOrder] = useState(false)
  const navigate = useNavigate()

  const { data, refetch } = useQuery({
    enabled: !!album,
    queryFn: async () => {
      const response = await getLiveshow(album!, { file_type: AlbumFileType.IMAGE })
      const files: Array<AlbumFile> = response.data.files

      return files
    },
    queryKey: ['drive', currentDrive?.id, 'liveshow'],
  })

  useEffect(() => {
    const refetchInterval = 30_000
    // const refetchInterval = 500
    const intervalId = setInterval(() => {
      refetch()
    }, refetchInterval)

    return () => clearInterval(intervalId)
  }, []);

  useEffect(() => {
    refetch()
  }, [album?.id]);

  function shuffle(array: Array<AlbumFile>) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }

  useEffect(() => {
    if (!data || !currentDrive) {
      return
    }

    const finalFiles = [
      ...data,
      ...(currentDrive.is_demo ? getDemoFilesForDriveType(currentDrive.drive_type) : [])
    ]

    // Shuffle
    if (isRandomOrder) {
      shuffle(finalFiles)
    }

    const currentFile = index ? renderAlbumFiles[index] : null
    if (currentFile) {
      const defaultIndexIfNotPresent = finalFiles.length > 0 ? 0 : null
      setIndex(getIndexOfFile(finalFiles, currentFile) || defaultIndexIfNotPresent)
    }

    setRenderAlbumFiles(finalFiles)
  }, [currentDrive, album?.id, data, isRandomOrder])

  const lightboxFiles = useMemo(() => {
    return renderAlbumFiles.map(albumFile => prepareForLightbox(albumFile))
  }, [renderAlbumFiles])

  function onStartClick() {
    setIndex(0)
  }

  function getIndexOfFile(files: Array<AlbumFile>, albumFile: AlbumFile) {
    return files.findIndex(file => file.id === albumFile.id)
  }

  function navigateToAlbum(album: Album) {
    navigate(`/d/${currentDrive!.id}/${album.id}/liveshow`)
  }

  if (!album || !currentDrive) {
    return <></>
  }


  return <div>
    <DriveHeader />
    <div className="container mx-auto px-4 flex flex-col gap-3 justify-center overflow-hidden items-center pb-5">
      <h2 className='font-serif text-center text-3xl'>{t('common.liveshow')}</h2>
      <Button color='primary' onClick={onStartClick}>
        {t('common.startLiveshow')}
      </Button>
      <h3 className='mt-5 text-xl font-bold'>{t('common.chooseAlbum')}</h3>
      <AlbumSelection drive={currentDrive} selectedAlbum={album} setSelectedAlbum={navigateToAlbum} />
      <p>{t('common.currentlyPictures', {count: renderAlbumFiles.length})}</p>
      <p></p>
      <h3 className='mt-5 text-xl font-bold'>{t('common.settings')}</h3>
      <label className="form-control flex flex-row items-center gap-3">
        <Checkbox color='primary' checked={isRandomOrder} onChange={() => setIsRandomOrder(!isRandomOrder)}/>
        <span className="label-text select-none">{t('common.randomOrder')}</span>
      </label>
      <p className='mt-3'>{t('common.secondsBetweenImages', {count: slideDelay})}</p>
      <Range className='bg-secondary' color='primary' value={slideDelay} min={1} max={30}
             onChange={e => setSlideDelay(parseInt(e.target.value))}/>
      { index != null && <>
        <meta name="theme-color" content="#000000" />
        <style>
          {
            'html, body { background-color: black !important; }'
          }
        </style>
      </>}
      <Lightbox
        className='lightbox-image-full'
        open={index != null}
        index={index || undefined}
        close={() => setIndex(null)}
        fullscreen={{auto: true}}
        slideshow={{autoplay: true, delay: slideDelay * 1000}}
        animation={{
          swipe: 0,
        }}
        on={{
          view: ({index}) => setIndex(index),
          click: () => setIndex((index! + 1) % lightboxFiles.length)
        }}
        plugins={[
          Slideshow,
          Fullscreen,
        ]}
        render={{
          buttonNext: () => null,
          buttonPrev: () => null,
        }}
        slides={lightboxFiles}
      />
    </div>
  </div>
}


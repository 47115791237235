import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'de', 'es', 'fr', 'no', 'pt', 'it', 'tr', 'bg', 'hr', 'ru', 'uk'],
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator'],
    },
    resources: {
      en: {
        translation: {
          common: {
            album: 'Album',
            games: 'Games',
            settings: 'Settings',
            drivePrivate: 'Private Gallery',
            save: 'Save',
            select: 'Select',
            cancel: 'Cancel',
            upload: 'Upload',
            back: 'Back',
            yesIDo: 'Yes, I do',
            alsoWantFridaysnap: 'I also want to use FridaySnap at my wedding',
            yesAppreciate: 'We at FridaySnap would be delighted to collect and make your memories unforgettable as well.',
            couponPc: '{{percent}}%-Coupon',
            couponReceive: 'Receive Coupon',
            couponSent: 'Coupon sent! It should arrive any moment 😊',
            couponSecure: 'Secure your personal <1/>{{percent}}% coupon',
            yourEmail: 'Your email address',
            delete: 'Delete',
            deleteQst: 'Delete file?',
            deleteRly: 'Do you really want to permanently delete the file?',
            all: 'All',
            allSelected: 'All selected',
            selectObjects: 'Select objects',
            deleteObjects: 'Delete objects',
            moveObjectsWhere: 'Where do you want to move the objects?',
            moveObjects: 'Move objects',
            deleteAllAlbumRly: 'Do you want to delete all objects from this album?',
            deleteObjectsRly: 'Do you want to delete the selected objects?',
            selected: '{{count}} selected',
            name: 'Name',
            changeImage: 'Change image',
            uploadImage: 'Upload image',
            removeImage: 'Remove image',
            myProfile: 'My Profile',
            generalSettings: 'General Settings',
            eventName: 'Event Name',
            moreSettingsAlbum: 'More settings can be found under "Albums".',
            colors: 'Colors',
            albums: 'Albums',
            new: 'New',
            createNewAlbum: 'Create new album',
            editAlbum: 'Edit album',
            qrCode: 'QR Code',
            liveshow: 'Liveshow',
            fullDownload: 'Full Download',
            helpSupport: 'Help and Support',
            previewImage: 'Preview image',
            description: 'Description',
            showGames: 'Show games',
            privacy: 'Privacy',
            guestsCanSeeAll: 'Guests can see all images/videos',
            guestsCanSeeAllDesc: 'If this option is disabled, guests can only upload and see their own images.',
            canUpload: 'Images/videos can be uploaded',
            thisAlbumIsListed: 'This album is listed',
            thisAlbumIsListedDesc: 'If this option is disabled, guests can only access the album via a link.',
            deleteAlbumQuestion: 'Delete album?',
            deleteAlbum: 'Delete album?',
            rlyDeleteAlbum: 'Do you really want to delete this album?',
            cantDeleteMainAlbum: 'This is the main album and cannot be deleted.',
            cantOnlyDeleteEmptyAlbums: 'Albums can only be deleted if they are empty. We will warn you if files remain.',
            chooseExampleImages: 'Choose sample colors',
            adaptColors: 'Adjust your colors',
            primary: 'Primary',
            background: 'Background',
            content: 'Content',
            requestFullDownload: 'Request full download',
            youWantFullDownload: 'Do you want to download all your images and videos at once?',
            liveshowExplanation: 'The liveshow is a slideshow that updates automatically and always shows the latest images.',
            liveshowExplanation2: 'You can display it on a TV, projector, tablet, laptop, etc.',
            liveshowExplanation3: 'If you want to show a private album, the device must be logged in via the couple’s link.',
            toLiveshow: 'To Liveshow',
            helpAndSupport: 'Help and Support',
            helpAndSupportQuestion: 'Need help or have a question? We are always here to help you <strong>personally</strong>! Either via email or WhatsApp:',
            openWhatsapp: 'Open WhatsApp',
            supportSubject: 'Question or Support ({{id}})',
            supportBody: 'Hello :) I have a question:',
            yourCurrentQrCode: 'Your current QR Code',
            designQRCode: 'Design QR Code',
            roundedCornersQuestion: 'Rounded corners?',
            adapt: 'Adjust',
            qrCodeColor: 'QR Code color',
            backgroundColor: 'Background color',
            done: 'Done',
            startLiveshow: 'Start liveshow',
            chooseAlbum: 'Choose album',
            currentlyPictures_one: 'Currently {{count}} picture',
            currentlyPictures_other: 'Currently {{count}} pictures',
            randomOrder: 'Random order',
            secondsBetweenImages_one: '{{count}} second per image',
            secondsBetweenImages_other: '{{count}} seconds per image',
            upgradeDemoTwoLines: 'Upgrade<br>demo',
            upgradeDemo: 'Upgrade demo',
            whatsappGuidanceTwoLines: 'WhatsApp<br/>guidance',
            thisIsYourDemo: 'This is your personal FridaySnap demo.',
            howToUpgrade: 'If you want to use FridaySnap at your event, you can simply <0>buy FridaySnap on our product page</0>. Just use the same email as this demo and your demo will automatically upgrade and you will receive your QR code',
            finishOnboarding: 'Finish setup',
            downloadCooldown: 'The next full download can only be requested in {{hours}} hours.',
            downloadAlreadyRequested: 'You have already requested a download. Please wait until it is processed.',
            downloadAlreadyExists: 'Please delete your existing full download to request a new one.',
            fileSize: 'File size',
            download: 'Download',
            createFullDownload: 'Create full download',
            status: 'Status',
            downloadRequestNeedsConfirmation: 'This full download will be relatively large. It must be confirmed by a technician beforehand. This usually happens within 24 hours.',
            downloadRequestInProgress: 'Download is being created. This usually takes 10-60 minutes.',
            downloadRequestFailed: 'Download creation failed. Please contact us via email or WhatsApp.',
            deleteThisFullDownload: 'Do you want to delete this full download?',
          },
          error: {
            stillImages: 'There are still files in the album. Please delete or move them to another album.',
          },
          games: {
            task: 'Task',
            add: 'Add game',
            adapt: 'Adapt games',
            savedWhere: 'The games are saved for this album',
            update: 'Update game',
            upload: 'Upload photo for the task',
            notCompleted: 'You have not completed this task yet',
            completed: 'You have completed this task!',
            beFirst: 'Nothing uploaded yet. Be the first person!'
          },
        },
      },
      de: {
        translation: {
          common: {
            album: 'Album',
            games: 'Spiele',
            settings: 'Einstellungen',
            drivePrivate: 'Private Galerie',
            save: 'Speichern',
            select: 'Auswählen',
            cancel: 'Abbrechen',
            upload: 'Hochladen',
            back: 'Zurück',
            yesIDo: 'Ja, ich will',
            alsoWantFridaysnap: 'FridaySnap auch auf meiner Hochzeit verwenden',
            yesAppreciate: 'Uns von FridaySnap würde es sehr freuen, auch deine Erinnerungen zu sammeln und unvergesslich zu machen.',
            couponPc: '{{percent}}%-Gutschein',
            couponReceive: 'Gutschein erhalten',
            couponSent: 'Gutschein verschickt! Er sollte jeden Moment ankommen 😊',
            couponSecure: 'Deinen persönlichen<1/>{{percent}}%-Gutschein sichern',
            yourEmail: 'Deine E-Mail Adresse',
            delete: 'Löschen',
            deleteQst: 'Datei löschen?',
            deleteRly: 'Möchtest du die Datei unwiderruflich löschen?',
            all: 'Alles',
            allSelected: 'Alles ausgewählt',
            selectObjects: 'Objekte auswählen',
            deleteObjects: 'Objekte löschen',
            moveObjectsWhere: 'Wohin möchtest du die Objekte bewegen?',
            moveObjects: 'Objekte bewegen',
            deleteAllAlbumRly: 'Möchtest du alle Objekte aus diesem Album löschen?',
            deleteObjectsRly: 'Möchtest du die ausgewählten Objekte löschen?',
            selected: '{{count}} ausgewählt',
            name: 'Name',
            changeImage: 'Bild ändern',
            uploadImage: 'Bild hochladen',
            removeImage: 'Bild entfernen',
            myProfile: 'Mein Profil',
            generalSettings: 'Allgemeine Einstellungen',
            eventName: 'Event-Name',
            moreSettingsAlbum: 'Weitere Einstellungen findest du unter "Alben".',
            colors: 'Farben',
            albums: 'Alben',
            new: 'Neu',
            createNewAlbum: 'Neues Album erstellen',
            editAlbum: 'Album bearbeiten',
            qrCode: 'QR-Code',
            liveshow: 'Liveshow',
            fullDownload: 'Komplett-Download',
            helpSupport: 'Hilfe und Support',
            previewImage: 'Vorschaubild',
            description: 'Beschreibung',
            showGames: 'Spiele anzeigen',
            privacy: 'Privatsphäre',
            guestsCanSeeAll: 'Gäste können alle Bilder/Videos sehen',
            guestsCanSeeAllDesc: 'Wenn diese Option deaktiviert ist, können Gäste nur hochladen und ihre eigenen Bilder sehen.',
            canUpload: 'Bilder/Videos können hochgeladen werden',
            thisAlbumIsListed: 'Dieses Album wird aufgelistet',
            thisAlbumIsListedDesc: 'Wenn diese Option deaktiviert ist, können Gäste das Album nur mit einem Link aufrufen.',
            deleteAlbumQuestion: 'Album löschen?',
            deleteAlbum: 'Album löschen?',
            rlyDeleteAlbum: 'Möchtest du dieses Album wirklich löschen?',
            cantDeleteMainAlbum: 'Das hier ist das Haupt-Album und kann nicht gelöscht werden.',
            cantOnlyDeleteEmptyAlbums: 'Alben können nur gelöscht werden, wenn sie leer sind. Wir warnen dich, falls noch Dateien vorhanden sind.',
            chooseExampleImages: 'Beispielfarben auswählen',
            adaptColors: 'Deine Farben anpassen',
            primary: 'Primär',
            background: 'Hintergrund',
            content: 'Inhalt',
            requestFullDownload: 'Komplett-Download anfordern',
            youWantFullDownload: 'Du möchtest alle deine Bilder und Videos auf einmal herunterladen?',
            liveshowExplanation: 'Die Liveshow ist eine Diashow, die sich automatisch aktualisiert und immer die neuesten Bilder anzeigt.',
            liveshowExplanation2: 'Ihr könnt sie also einfach auf einem Fernseher, Beamer, Tablet, Laptop etc. anzeigen lassen.',
            liveshowExplanation3: 'Falls ihr ein privates Album anzeigen wollt, muss das anzeigende Gerät über den Brautpaar-Link eingeloggt sein.',
            toLiveshow: 'Zur Liveshow',
            helpAndSupport: 'Hilfe und Support',
            helpAndSupportQuestion: 'Ihr braucht Hilfe oder habt eine Frage? Wir helfen euch jederzeit <strong>persönlich</strong> weiter! Entweder über Mail oder WhatsApp:',
            openWhatsapp: 'WhatsApp öffnen',
            supportSubject: 'Frage oder Support ({{id}})',
            supportBody: 'Hallo :) Ich habe eine Frage:',
            yourCurrentQrCode: 'Dein aktueller QR-Code',
            designQRCode: 'QR-Code gestalten',
            roundedCornersQuestion: 'Runde Ecken?',
            adapt: 'anpassen',
            qrCodeColor: 'QR-Code Farbe',
            backgroundColor: 'Hintergrund Farbe',
            done: 'Fertig',
            startLiveshow: 'Liveshow starten',
            chooseAlbum: 'Album auswählen',
            currentlyPictures_one: 'Aktuell {{count}} Bild',
            currentlyPictures_other: 'Aktuell {{count}} Bilder',
            randomOrder: 'Zufällige Reihenfolge',
            secondsBetweenImages_one: '{{count}} Sekunde pro Bild',
            secondsBetweenImages_other: '{{count}} Sekunden pro Bild',
            upgradeDemoTwoLines: 'Demo<br>upgraden',
            upgradeDemo: 'Demo upgraden',
            whatsappGuidanceTwoLines: 'WhatsApp<br/>Beratung',
            thisIsYourDemo: 'Das hier ist eure persönliche FridaySnap Demo.',
            howToUpgrade: 'Wenn ihr FridaySnap auch auf eurem Event verwenden möchtet, dann könnt ihr einfach <0>FridaySnap auf unserer Produktseite kaufen</0>. Einfach die gleiche E-Mail wie bei dieser Demo angeben und eure Demo wird automatisch das Upgrade und ihr euren QR-Code erhalten',
            finishOnboarding: 'Einrichtung abschließen',
            downloadCooldown: 'Der nächste Komplett-Download kann erst in {{hours}} Stunden angefragt werden.',
            downloadAlreadyRequested: 'Du hast bereits einen Download angefragt. Bitte warte, bis er bearbeitet wurde.',
            downloadAlreadyExists: 'Bitte lösche deinen existierenden Komplett-Download, um einen neuen anzufragen.',
            fileSize: 'Dateigröße',
            download: 'Download',
            createFullDownload: 'Komplett-Download erstellen',
            status: 'Status',
            downloadRequestNeedsConfirmation: 'Dieser Komplett-Download wird relativ groß. Er muss vorher von einem Techniker bestätigt werden. Das geschieht innerhalb von 24 Stunden.',
            downloadRequestInProgress: 'Download wird erstellt. Das dauert in der Regel 10-60 Minuten.',
            downloadRequestFailed: 'Download-Erstellung fehlgeschlagen. Bitte kontaktiere uns über Mail oder WhatsApp.',
            deleteThisFullDownload: 'Möchtest du diesen Komplett-Download löschen?',
          },
          error: {
            stillImages: 'Es sind noch Dateien im Album vorhanden. Bitte löschen oder in ein anderes Album verschieben.',
          },
          games: {
            task: 'Aufgabe',
            add: 'Spiel hinzufügen',
            adapt: 'Spiele anpassen',
            savedWhere: 'Die Spiele werden für dieses Album gespeichert',
            update: 'Spiel aktualisieren',
            upload: 'Foto zur Aufgabe hochladen',
            notCompleted: 'Du hast diese Aufgabe noch nicht abgeschlossen',
            completed: 'Du hast diese Aufgabe abgeschlossen!',
            beFirst: 'Hier ist noch nichts hochgeladen. Sei die erste Person!',
          },
        },
      },
      es: {
        translation: {
          common: {
            album: 'Álbum',
            games: 'Juegos',
            settings: 'Configuración',
            drivePrivate: 'Galería Privada',
            save: 'Guardar',
            select: 'Seleccionar',
            cancel: 'Cancelar',
            upload: 'Subir',
            back: 'Atrás',
            yesIDo: 'Sí, quiero',
            alsoWantFridaysnap: 'También quiero usar FridaySnap en mi boda',
            yesAppreciate: 'En FridaySnap estaríamos encantados de recopilar y hacer que tus recuerdos sean inolvidables.',
            couponPc: 'Cupón de {{percent}}%',
            couponReceive: 'Recibir cupón',
            couponSent: '¡Cupón enviado! Debería llegar en cualquier momento 😊',
            couponSecure: 'Asegura tu cupón personal del {{percent}}%',
            yourEmail: 'Tu dirección de correo electrónico',
            delete: 'Eliminar',
            deleteQst: '¿Eliminar archivo?',
            deleteRly: '¿Realmente deseas eliminar el archivo permanentemente?',
            all: 'Todo',
            allSelected: 'Todo seleccionado',
            selectObjects: 'Seleccionar objetos',
            deleteObjects: 'Eliminar objetos',
            moveObjectsWhere: '¿Dónde quieres mover los objetos?',
            moveObjects: 'Mover objetos',
            deleteAllAlbumRly: '¿Quieres eliminar todos los objetos de este álbum?',
            deleteObjectsRly: '¿Quieres eliminar los objetos seleccionados?',
            selected: '{{count}} seleccionado(s)'
          },
          games: {
            task: 'Tarea',
            add: 'Agregar juego',
            adapt: 'Adaptar juegos',
            savedWhere: 'Los juegos se guardan para este álbum',
            update: 'Actualizar juego',
            upload: 'Subir foto para la tarea',
            notCompleted: 'Aún no has completado esta tarea',
            completed: '¡Has completado esta tarea!',
            beFirst: 'Aún no se ha subido nada. ¡Sé la primera persona!'
          }
        }
      },
      fr: {
        translation: {
          common: {
            album: 'Album',
            games: 'Jeux',
            settings: 'Paramètres',
            drivePrivate: 'Galerie Privée',
            save: 'Enregistrer',
            select: 'Sélectionner',
            cancel: 'Annuler',
            upload: 'Télécharger',
            back: 'Retour',
            yesIDo: 'Oui, je veux',
            alsoWantFridaysnap: 'Je veux aussi utiliser FridaySnap à mon mariage',
            yesAppreciate: 'Nous serions ravis chez FridaySnap de collecter et rendre vos souvenirs inoubliables.',
            couponPc: 'Coupon de {{percent}}%',
            couponReceive: 'Recevoir le coupon',
            couponSent: 'Coupon envoyé ! Il devrait arriver d\'un moment à l\'autre 😊',
            couponSecure: 'Obtenez votre coupon personnel de {{percent}}%',
            yourEmail: 'Votre adresse e-mail',
            delete: 'Supprimer',
            deleteQst: 'Supprimer le fichier ?',
            deleteRly: 'Voulez-vous vraiment supprimer définitivement le fichier ?',
            all: 'Tout',
            allSelected: 'Tout sélectionné',
            selectObjects: 'Sélectionner des objets',
            deleteObjects: 'Supprimer des objets',
            moveObjectsWhere: 'Où voulez-vous déplacer les objets ?',
            moveObjects: 'Déplacer des objets',
            deleteAllAlbumRly: 'Voulez-vous supprimer tous les objets de cet album ?',
            deleteObjectsRly: 'Voulez-vous supprimer les objets sélectionnés ?',
            selected: '{{count}} sélectionné(s)'
          },
          games: {
            task: 'Tâche',
            add: 'Ajouter un jeu',
            adapt: 'Adapter les jeux',
            savedWhere: 'Les jeux sont enregistrés pour cet album',
            update: 'Mettre à jour le jeu',
            upload: 'Télécharger une photo pour la tâche',
            notCompleted: 'Vous n\'avez pas encore complété cette tâche',
            completed: 'Vous avez complété cette tâche !',
            beFirst: 'Rien n\'a encore été téléchargé. Soyez la première personne !'
          }
        }
      },
      it: {
        translation: {
          common: {
            album: 'Album',
            games: 'Giochi',
            settings: 'Impostazioni',
            drivePrivate: 'Galleria Privata',
            save: 'Salva',
            select: 'Seleziona',
            cancel: 'Annulla',
            upload: 'Carica',
            back: 'Indietro',
            yesIDo: 'Sì, lo voglio',
            alsoWantFridaysnap: 'Voglio anche usare FridaySnap al mio matrimonio',
            yesAppreciate: 'Noi di FridaySnap saremmo felici di raccogliere e rendere indimenticabili i tuoi ricordi.',
            couponPc: 'Coupon del {{percent}}%',
            couponReceive: 'Ricevi il coupon',
            couponSent: 'Coupon inviato! Dovrebbe arrivare a breve 😊',
            couponSecure: 'Assicura il tuo coupon personale del {{percent}}%',
            yourEmail: 'Il tuo indirizzo email',
            delete: 'Elimina',
            deleteQst: 'Eliminare il file?',
            deleteRly: 'Vuoi davvero eliminare definitivamente il file?',
            all: 'Tutto',
            allSelected: 'Tutto selezionato',
            selectObjects: 'Seleziona oggetti',
            deleteObjects: 'Elimina oggetti',
            moveObjectsWhere: 'Dove vuoi spostare gli oggetti?',
            moveObjects: 'Sposta oggetti',
            deleteAllAlbumRly: 'Vuoi eliminare tutti gli oggetti di questo album?',
            deleteObjectsRly: 'Vuoi eliminare gli oggetti selezionati?',
            selected: '{{count}} selezionato/i'
          },
          games: {
            task: 'Compito',
            add: 'Aggiungi gioco',
            adapt: 'Adatta giochi',
            savedWhere: 'I giochi sono salvati per questo album',
            update: 'Aggiorna gioco',
            upload: 'Carica foto per il compito',
            notCompleted: 'Non hai ancora completato questo compito',
            completed: 'Hai completato questo compito!',
            beFirst: 'Nessun caricamento ancora. Sii la prima persona!'
          }
        }
      },
      tr: {
        translation: {
          common: {
            album: 'Albüm',
            games: 'Oyunlar',
            settings: 'Ayarlar',
            drivePrivate: 'Özel Galeri',
            save: 'Kaydet',
            select: 'Seç',
            cancel: 'İptal',
            upload: 'Yükle',
            back: 'Geri',
            yesIDo: 'Evet, istiyorum',
            alsoWantFridaysnap: 'Düğünümde FridaySnap kullanmak istiyorum',
            yesAppreciate: 'FridaySnap olarak anılarınızı toplamaktan ve unutulmaz kılmaktan memnuniyet duyarız.',
            couponPc: '{{percent}}%-Kupon',
            couponReceive: 'Kuponu Al',
            couponSent: 'Kupon gönderildi! Her an ulaşabilir 😊',
            couponSecure: 'Kişisel {{percent}}%-kuponunuzu güvenceye alın',
            yourEmail: 'E-posta adresiniz',
            delete: 'Sil',
            deleteQst: 'Dosyayı sil?',
            deleteRly: 'Dosyayı kalıcı olarak silmek istediğinizden emin misiniz?',
            all: 'Hepsi',
            allSelected: 'Hepsi seçildi',
            selectObjects: 'Öğeleri seç',
            deleteObjects: 'Öğeleri sil',
            moveObjectsWhere: 'Öğeleri nereye taşımak istiyorsunuz?',
            moveObjects: 'Öğeleri taşı',
            deleteAllAlbumRly: 'Bu albümdeki tüm öğeleri silmek istiyor musunuz?',
            deleteObjectsRly: 'Seçilen öğeleri silmek istiyor musunuz?',
            selected: '{{count}} seçildi'
          },
          games: {
            task: 'Görev',
            add: 'Oyun ekle',
            adapt: 'Oyunları uyarlayın',
            savedWhere: 'Oyunlar bu albüm için kaydedildi',
            update: 'Oyunu güncelle',
            upload: 'Görev için fotoğraf yükle',
            notCompleted: 'Bu görevi henüz tamamlamadınız',
            completed: 'Bu görevi tamamladınız!',
            beFirst: 'Henüz hiçbir şey yüklenmedi. İlk kişi siz olun!'
          }
        }
      },
      pt: {
        translation: {
          common: {
            album: 'Álbum',
            games: 'Jogos',
            settings: 'Configurações',
            drivePrivate: 'Galeria Privada',
            save: 'Salvar',
            select: 'Selecionar',
            cancel: 'Cancelar',
            upload: 'Carregar',
            back: 'Voltar',
            yesIDo: 'Sim, eu quero',
            alsoWantFridaysnap: 'Também quero usar o FridaySnap no meu casamento',
            yesAppreciate: 'Nós do FridaySnap ficaríamos muito felizes em coletar e tornar suas memórias inesquecíveis.',
            couponPc: 'Cupom de {{percent}}%',
            couponReceive: 'Receber cupom',
            couponSent: 'Cupom enviado! Deve chegar a qualquer momento 😊',
            couponSecure: 'Garanta seu cupom pessoal de {{percent}}%',
            yourEmail: 'Seu endereço de e-mail',
            delete: 'Excluir',
            deleteQst: 'Excluir arquivo?',
            deleteRly: 'Deseja realmente excluir permanentemente o arquivo?',
            all: 'Tudo',
            allSelected: 'Tudo selecionado',
            selectObjects: 'Selecionar objetos',
            deleteObjects: 'Excluir objetos',
            moveObjectsWhere: 'Para onde deseja mover os objetos?',
            moveObjects: 'Mover objetos',
            deleteAllAlbumRly: 'Deseja excluir todos os objetos deste álbum?',
            deleteObjectsRly: 'Deseja excluir os objetos selecionados?',
            selected: '{{count}} selecionado(s)'
          },
          games: {
            task: 'Tarefa',
            add: 'Adicionar jogo',
            adapt: 'Adaptar jogos',
            savedWhere: 'Os jogos são salvos para este álbum',
            update: 'Atualizar jogo',
            upload: 'Carregar foto para a tarefa',
            notCompleted: 'Você ainda não completou esta tarefa',
            completed: 'Você completou esta tarefa!',
            beFirst: 'Nada carregado ainda. Seja a primeira pessoa!'
          },
        },
      },
      hr: {
        translation: {
          common: {
            album: 'Album',
            games: 'Igre',
            settings: 'Postavke',
            drivePrivate: 'Privatna Galerija',
            save: 'Spremi',
            select: 'Odaberi',
            cancel: 'Otkaži',
            upload: 'Prenesi',
            back: 'Natrag',
            yesIDo: 'Da, želim',
            alsoWantFridaysnap: 'Također želim koristiti FridaySnap na svom vjenčanju',
            yesAppreciate: 'U FridaySnapu bismo bili oduševljeni prikupiti i učiniti vaše uspomene nezaboravnima.',
            couponPc: 'Kupon od {{percent}}%',
            couponReceive: 'Preuzmi kupon',
            couponSent: 'Kupon poslan! Trebao bi stići svakog trenutka 😊',
            couponSecure: 'Osigurajte svoj osobni kupon od {{percent}}%',
            yourEmail: 'Vaša e-mail adresa',
            delete: 'Izbriši',
            deleteQst: 'Izbrisati datoteku?',
            deleteRly: 'Želite li stvarno trajno izbrisati datoteku?',
            all: 'Sve',
            allSelected: 'Sve odabrano',
            selectObjects: 'Odaberi objekte',
            deleteObjects: 'Izbriši objekte',
            moveObjectsWhere: 'Gdje želite premjestiti objekte?',
            moveObjects: 'Premjesti objekte',
            deleteAllAlbumRly: 'Želite li izbrisati sve objekte iz ovog albuma?',
            deleteObjectsRly: 'Želite li izbrisati odabrane objekte?',
            selected: '{{count}} odabrano'
          },
          games: {
            task: 'Zadatak',
            add: 'Dodaj igru',
            adapt: 'Prilagodi igre',
            savedWhere: 'Igre su spremljene za ovaj album',
            update: 'Ažuriraj igru',
            upload: 'Prenesi fotografiju za zadatak',
            notCompleted: 'Još niste dovršili ovaj zadatak',
            completed: 'Dovršili ste ovaj zadatak!',
            beFirst: 'Još ništa nije preneseno. Budite prva osoba!'
          }
        }
      },
      bg: {
        translation: {
          common: {
            album: 'Албум',
            games: 'Игри',
            settings: 'Настройки',
            drivePrivate: 'Частна Галерия',
            save: 'Запази',
            select: 'Избери',
            cancel: 'Отказ',
            upload: 'Качи',
            back: 'Назад',
            yesIDo: 'Да, искам',
            alsoWantFridaysnap: 'Искам също да използвам FridaySnap на моята сватба',
            yesAppreciate: 'Ние от FridaySnap ще се радваме да съберем и направим вашите спомени незабравими.',
            couponPc: 'Купон от {{percent}}%',
            couponReceive: 'Получаване на купон',
            couponSent: 'Купонът е изпратен! Трябва да пристигне всеки момент 😊',
            couponSecure: 'Осигурете своя персонален купон от {{percent}}%',
            yourEmail: 'Вашият имейл адрес',
            delete: 'Изтрий',
            deleteQst: 'Изтриване на файла?',
            deleteRly: 'Наистина ли искате да изтриете файла завинаги?',
            all: 'Всичко',
            allSelected: 'Всичко е избрано',
            selectObjects: 'Избери обекти',
            deleteObjects: 'Изтрий обекти',
            moveObjectsWhere: 'Къде искате да преместите обектите?',
            moveObjects: 'Премести обекти',
            deleteAllAlbumRly: 'Искате ли да изтриете всички обекти от този албум?',
            deleteObjectsRly: 'Искате ли да изтриете избраните обекти?',
            selected: '{{count}} избрано'
          },
          games: {
            task: 'Задача',
            add: 'Добави игра',
            adapt: 'Адаптиране на игри',
            savedWhere: 'Игрите са запазени за този албум',
            update: 'Актуализиране на игра',
            upload: 'Качи снимка за задачата',
            notCompleted: 'Все още не сте завършили тази задача',
            completed: 'Завършихте тази задача!',
            beFirst: 'Все още няма качени снимки. Бъдете първият човек!'
          }
        }
      },
      no: {
        translation: {
          common: {
            album: 'Album',
            games: 'Spill',
            settings: 'Innstillinger',
            drivePrivate: 'Privat Galleri',
            save: 'Lagre',
            select: 'Velg',
            cancel: 'Avbryt',
            upload: 'Last opp',
            back: 'Tilbake',
            yesIDo: 'Ja, jeg vil',
            alsoWantFridaysnap: 'Jeg vil også bruke FridaySnap i mitt bryllup',
            yesAppreciate: 'Vi i FridaySnap vil være glade for å samle og gjøre minnene dine uforglemmelige.',
            couponPc: 'Kupong på {{percent}}%',
            couponReceive: 'Motta kupong',
            couponSent: 'Kupongen er sendt! Den bør ankomme hvert øyeblikk 😊',
            couponSecure: 'Sikre din personlige kupong på {{percent}}%',
            yourEmail: 'Din e-postadresse',
            delete: 'Slett',
            deleteQst: 'Slette fil?',
            deleteRly: 'Vil du virkelig slette filen permanent?',
            all: 'Alle',
            allSelected: 'Alt valgt',
            selectObjects: 'Velg objekter',
            deleteObjects: 'Slett objekter',
            moveObjectsWhere: 'Hvor vil du flytte objektene?',
            moveObjects: 'Flytt objekter',
            deleteAllAlbumRly: 'Vil du slette alle objektene fra dette albumet?',
            deleteObjectsRly: 'Vil du slette de valgte objektene?',
            selected: '{{count}} valgt'
          },
          games: {
            task: 'Oppgave',
            add: 'Legg til spill',
            adapt: 'Tilpass spill',
            savedWhere: 'Spillene lagres for dette albumet',
            update: 'Oppdater spill',
            upload: 'Last opp bilde til oppgaven',
            notCompleted: 'Du har ikke fullført denne oppgaven ennå',
            completed: 'Du har fullført denne oppgaven!',
            beFirst: 'Ingenting lastet opp ennå. Bli den første personen!'
          }
        }
      },
      ru: {
        translation: {
          common: {
            album: 'Альбом',
            games: 'Игры',
            settings: 'Настройки',
            drivePrivate: 'Личная Галерея',
            save: 'Сохранить',
            select: 'Выбрать',
            cancel: 'Отмена',
            upload: 'Загрузить',
            back: 'Назад',
            yesIDo: 'Да, я хочу',
            alsoWantFridaysnap: 'Я также хочу использовать FridaySnap на своей свадьбе',
            yesAppreciate: 'Мы в FridaySnap будем рады собрать и сохранить ваши воспоминания.',
            couponPc: 'Купон на {{percent}}%',
            couponReceive: 'Получить купон',
            couponSent: 'Купон отправлен! Он должен прийти в ближайшее время 😊',
            couponSecure: 'Получите ваш персональный купон на {{percent}}%',
            yourEmail: 'Ваш адрес электронной почты',
            delete: 'Удалить',
            deleteQst: 'Удалить файл?',
            deleteRly: 'Вы действительно хотите безвозвратно удалить файл?',
            all: 'Все',
            allSelected: 'Все выбрано',
            selectObjects: 'Выбрать объекты',
            deleteObjects: 'Удалить объекты',
            moveObjectsWhere: 'Куда вы хотите переместить объекты?',
            moveObjects: 'Переместить объекты',
            deleteAllAlbumRly: 'Вы хотите удалить все объекты из этого альбома?',
            deleteObjectsRly: 'Вы хотите удалить выбранные объекты?',
            selected: '{{count}} выбрано'
          },
          games: {
            task: 'Задание',
            add: 'Добавить игру',
            adapt: 'Настроить игры',
            savedWhere: 'Игры сохранены для этого альбома',
            update: 'Обновить игру',
            upload: 'Загрузить фото для задания',
            notCompleted: 'Вы еще не завершили это задание',
            completed: 'Вы завершили это задание!',
            beFirst: 'Ничего еще не загружено. Будьте первым!'
          }
        }
      },
      uk: {
        translation: {
          common: {
            album: 'Альбом',
            games: 'Ігри',
            settings: 'Налаштування',
            drivePrivate: 'Приватна Галерея',
            save: 'Зберегти',
            select: 'Вибрати',
            cancel: 'Скасувати',
            upload: 'Завантажити',
            back: 'Назад',
            yesIDo: 'Так, я хочу',
            alsoWantFridaysnap: 'Я також хочу використовувати FridaySnap на своєму весіллі',
            yesAppreciate: 'Ми в FridaySnap будемо раді зберегти ваші спогади і зробити їх незабутніми.',
            couponPc: 'Купон на {{percent}}%',
            couponReceive: 'Отримати купон',
            couponSent: 'Купон відправлено! Він повинен надійти найближчим часом 😊',
            couponSecure: 'Отримайте свій персональний купон на {{percent}}%',
            yourEmail: 'Ваша електронна адреса',
            delete: 'Видалити',
            deleteQst: 'Видалити файл?',
            deleteRly: 'Ви дійсно хочете безповоротно видалити файл?',
            all: 'Все',
            allSelected: 'Все вибрано',
            selectObjects: 'Вибрати об’єкти',
            deleteObjects: 'Видалити об’єкти',
            moveObjectsWhere: 'Куди ви хочете перемістити об’єкти?',
            moveObjects: 'Перемістити об’єкти',
            deleteAllAlbumRly: 'Ви хочете видалити всі об’єкти з цього альбому?',
            deleteObjectsRly: 'Ви хочете видалити вибрані об’єкти?',
            selected: '{{count}} вибрано'
          },
          games: {
            task: 'Завдання',
            add: 'Додати гру',
            adapt: 'Налаштувати ігри',
            savedWhere: 'Ігри збережено для цього альбому',
            update: 'Оновити гру',
            upload: 'Завантажити фото для завдання',
            notCompleted: 'Ви ще не виконали це завдання',
            completed: 'Ви виконали це завдання!',
            beFirst: 'Ще нічого не завантажено. Будьте першими!'
          }
        }
      },
      lt: {
        translation: {
          common: {
            album: 'Albumas',
            games: 'Žaidimai',
            settings: 'Nustatymai',
            drivePrivate: 'Privati galerija',
            save: 'Išsaugoti',
            select: 'Pasirinkti',
            cancel: 'Atšaukti',
            upload: 'Įkelti',
            back: 'Atgal',
            yesIDo: 'Taip, aš noriu',
            alsoWantFridaysnap: 'Aš taip pat noriu naudoti FridaySnap savo vestuvėse',
            yesAppreciate: 'Mes, FridaySnap, džiaugtumėmės galėdami surinkti ir padaryti jūsų prisiminimus nepamirštamus.',
            couponPc: '{{percent}}% kuponas',
            couponReceive: 'Gauti kuponą',
            couponSent: 'Kuponas išsiųstas! Jis turėtų atvykti bet kuriuo metu 😊',
            couponSecure: 'Užtikrinkite savo asmeninį {{percent}}% kuponą',
            yourEmail: 'Jūsų el. pašto adresas',
            delete: 'Ištrinti',
            deleteQst: 'Ištrinti failą?',
            deleteRly: 'Ar tikrai norite visam laikui ištrinti failą?',
            all: 'Viskas',
            allSelected: 'Viskas pasirinkta',
            selectObjects: 'Pasirinkite objektus',
            deleteObjects: 'Ištrinti objektus',
            moveObjectsWhere: 'Kur norite perkelti objektus?',
            moveObjects: 'Perkelti objektus',
            deleteAllAlbumRly: 'Ar norite ištrinti visus objektus iš šio albumo?',
            deleteObjectsRly: 'Ar norite ištrinti pasirinktus objektus?',
            selected: '{{count}} pasirinkta'
          },
          games: {
            task: 'Užduotis',
            add: 'Pridėti žaidimą',
            adapt: 'Pritaikyti žaidimus',
            savedWhere: 'Žaidimai išsaugoti šiam albumui',
            update: 'Atnaujinti žaidimą',
            upload: 'Įkelti nuotrauką užduočiai',
            notCompleted: 'Jūs dar neįvykdėte šios užduoties',
            completed: 'Jūs įvykdėte šią užduotį!',
            beFirst: 'Nieko dar neįkelta. Būkite pirmas!'
          }
        }
      },
      el: {
        translation: {
          common: {
            album: 'Άλμπουμ',
            games: 'Παιχνίδια',
            settings: 'Ρυθμίσεις',
            drivePrivate: 'Ιδιωτική Γκαλερί',
            save: 'Αποθήκευση',
            select: 'Επιλογή',
            cancel: 'Ακύρωση',
            upload: 'Μεταφόρτωση',
            back: 'Πίσω',
            yesIDo: 'Ναι, θέλω',
            alsoWantFridaysnap: 'Θέλω επίσης να χρησιμοποιήσω το FridaySnap στον γάμο μου',
            yesAppreciate: 'Εμείς στο FridaySnap θα χαρούμε πολύ να συλλέξουμε και να κάνουμε τις αναμνήσεις σας αξέχαστες.',
            couponPc: 'Κουπόνι {{percent}}%',
            couponReceive: 'Λήψη κουπονιού',
            couponSent: 'Το κουπόνι εστάλη! Πρέπει να φτάσει ανά πάσα στιγμή 😊',
            couponSecure: 'Εξασφαλίστε το προσωπικό σας κουπόνι {{percent}}%',
            yourEmail: 'Η διεύθυνση email σας',
            delete: 'Διαγραφή',
            deleteQst: 'Διαγραφή αρχείου;',
            deleteRly: 'Θέλετε πραγματικά να διαγράψετε μόνιμα το αρχείο;',
            all: 'Όλα',
            allSelected: 'Όλα επιλεγμένα',
            selectObjects: 'Επιλέξτε αντικείμενα',
            deleteObjects: 'Διαγραφή αντικειμένων',
            moveObjectsWhere: 'Πού θέλετε να μετακινήσετε τα αντικείμενα;',
            moveObjects: 'Μετακίνηση αντικειμένων',
            deleteAllAlbumRly: 'Θέλετε να διαγράψετε όλα τα αντικείμενα από αυτό το άλμπουμ;',
            deleteObjectsRly: 'Θέλετε να διαγράψετε τα επιλεγμένα αντικείμενα;',
            selected: '{{count}} επιλεγμένα'
          },
          games: {
            task: 'Αποστολή',
            add: 'Προσθήκη παιχνιδιού',
            adapt: 'Προσαρμογή παιχνιδιών',
            savedWhere: 'Τα παιχνίδια αποθηκεύτηκαν για αυτό το άλμπουμ',
            update: 'Ενημέρωση παιχνιδιού',
            upload: 'Μεταφόρτωση φωτογραφίας για την αποστολή',
            notCompleted: 'Δεν έχετε ολοκληρώσει αυτήν την αποστολή',
            completed: 'Ολοκληρώσατε αυτήν την αποστολή!',
            beFirst: 'Δεν έχει μεταφορτωθεί τίποτα ακόμα. Γίνετε το πρώτο άτομο!'
          }
        }
      },
      sr: {
        translation: {
          common: {
            album: 'Албум',
            games: 'Игре',
            settings: 'Подешавања',
            drivePrivate: 'Приватна галерија',
            save: 'Сачувај',
            select: 'Изабери',
            cancel: 'Откажи',
            upload: 'Отпреми',
            back: 'Назад',
            yesIDo: 'Да, желим',
            alsoWantFridaysnap: 'Такође желим да користим FridaySnap на свом венчању',
            yesAppreciate: 'Ми у FridaySnap-у бисмо били одушевљени да сакупимо и учинимо ваше успомене незаборавним.',
            couponPc: 'Купон од {{percent}}%',
            couponReceive: 'Прими купон',
            couponSent: 'Купон је послат! Требало би да стигне сваког тренутка 😊',
            couponSecure: 'Обезбедите свој лични купон од {{percent}}%',
            yourEmail: 'Ваша адреса е-поште',
            delete: 'Обриши',
            deleteQst: 'Обриши датотеку?',
            deleteRly: 'Да ли заиста желите трајно да обришете датотеку?',
            all: 'Све',
            allSelected: 'Све изабрано',
            selectObjects: 'Изаберите објекте',
            deleteObjects: 'Обриши објекте',
            moveObjectsWhere: 'Где желите да преместите објекте?',
            moveObjects: 'Премести објекте',
            deleteAllAlbumRly: 'Да ли желите да обришете све објекте из овог албума?',
            deleteObjectsRly: 'Да ли желите да обришете изабране објекте?',
            selected: '{{count}} изабрано'
          },
          games: {
            task: 'Задатак',
            add: 'Додај игру',
            adapt: 'Прилагоди игре',
            savedWhere: 'Игре су сачуване за овај албум',
            update: 'Ажурирај игру',
            upload: 'Отпремите фотографију за задатак',
            notCompleted: 'Још увек нисте завршили овај задатак',
            completed: 'Завршили сте овај задатак!',
            beFirst: 'Ништа још није отпремљено. Будите прва особа!'
          }
        }
      },
    },
  });

export default i18n;

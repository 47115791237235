import { Button, Modal } from 'react-daisyui'
import { useEffect, useRef } from 'react'
import { useProfile } from '@/stores/useAuth.ts'
import { useDrives } from '@/stores/useDrives.ts'

const hasSeenKey = 'hey-to-friday'

export default function NameChangedModal() {
  const { profile } = useProfile()
  const { currentDrive } = useDrives()
  const modalRef = useRef<HTMLDialogElement>(null)

  function onOkClick() {
    localStorage.setItem(hasSeenKey, 'true')
    modalRef.current!.close()
  }

  useEffect(() => {
    if (!profile || !currentDrive) {
      return
    }

    if (currentDrive.author !== profile.username) {
      return
    }

    if (new Date(currentDrive.created_at).getTime() - new Date('2024-09-05T11:29:15.924Z').getTime() > 0) {
      return
    }

    const hasSeen = localStorage.getItem(hasSeenKey)
    if (hasSeen === null || hasSeen === 'false') {
      modalRef.current!.showModal()
    }
  }, [profile, currentDrive]);

  return <Modal ref={modalRef}>
    <Modal.Body>
      <div className='flex flex-col items-center gap-3 text-center'>
        <p>
          {/*<span className='text-2xl'>*/}
          {/*  FridaySnap<br/>*/}
          {/*</span>*/}
          {/*<span className='text-xl'>*/}
          {/*  heißt nun<br/>*/}
          {/*</span>*/}
          <span className='text-xl'>
            Wir heißen ab jetzt<br/>
          </span>
          <span className='text-3xl'>
            FridaySnap
          </span>
        </p>
        <p>Nur der Name ist anders, sonst bleibt alles gleich :)</p>
        <p>Ihr müsst von eurer Seite nichts machen. Alle alten QR-Codes funktionieren natürlich noch und ihr benötigt keine neuen.</p>
        <p className='my-2'>Bei Fragen sind wir wie immer auf <a className=' text-primary'
                                                                 href='https://wa.me/496987000170'
                                                                 target='_blank'>WhatsApp</a> erreichbar</p>
        <p className='mb-2'>Liebe Grüße, Dennis</p>
        <Button color='primary' className='px-5' onClick={onOkClick}>OK</Button>
      </div>
    </Modal.Body>
  </Modal>
}

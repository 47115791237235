import { Outlet } from 'react-router-dom'
import NavigationTabs from '@/components/NavigationTabs.tsx'
import { FilesProvider } from '@/contexts/filesContext.tsx'

export default function GenericDrivePage() {
  return <div className='pb-[3rem] bg-neutral h-full'>
    <FilesProvider>
      <Outlet/>
      <NavigationTabs/>
    </FilesProvider>
  </div>
}

export enum LanguageSite {
  DE = 'de',
  CO_UK = 'co.uk',
}

export function getCurrentLanguageSite(): LanguageSite {
  const hostname = window.location.hostname
  if (hostname.endsWith('.co.uk')) {
    return LanguageSite.CO_UK
  }

  return LanguageSite.DE
}

export const ProductPagesForLanguageSite = {
  [LanguageSite.DE]: 'https://fridaysnap.de/produkt/qr-code-hochzeit-fotos/',
  [LanguageSite.CO_UK]: 'https://fridaysnap.co.uk/product/wedding-qr-code-photos/',
}

export const EmailAddressForLanguageSite = {
  [LanguageSite.DE]: 'hello@fridaysnap.de',
  [LanguageSite.CO_UK]: 'hello@fridaysnap.co.uk',
}

export function getCurrentLanguageInfos() {
  const lang = getCurrentLanguageSite()

  return {
    weddingProductPage: ProductPagesForLanguageSite[lang],
    email: EmailAddressForLanguageSite[lang],
  }
}


import { create } from 'zustand'
import { HeyProfile } from '@/models/profile.ts'
import { produce } from 'immer'
import * as Sentry from "@sentry/react";

const AUTH_TOKEN_KEY = 'authenticationToken'

interface UseAuthState {
  profile: HeyProfile | null
  authenticationToken: string | null
}

interface UseAuthActions {
  setProfile: (profile: HeyProfile) => void
  setAuthenticationToken: (token: string | null) => void
}

export const useProfile = create<UseAuthState & UseAuthActions>((set) => {
  const tokenFromUri = new URLSearchParams(document.location.search).get('_ha') || null
  console.log('yoo checking')
  if (tokenFromUri) {
    localStorage.setItem(AUTH_TOKEN_KEY, tokenFromUri)
  }
  const tokenFromStorage = localStorage.getItem(AUTH_TOKEN_KEY)
  return {
    profile: null,
    authenticationToken: tokenFromUri || tokenFromStorage || null,
    setProfile(profile) {
      set(produce(state => {
        state.profile = profile
      }))
      if (profile) {
        Sentry.setUser({
          id: profile.username,
        })
      } else {
        Sentry.setUser(null)
      }
    },
    setAuthenticationToken(authenticationToken) {
      set(produce(state => {
        state.authenticationToken = authenticationToken
        if (authenticationToken) {
          localStorage.setItem(AUTH_TOKEN_KEY, authenticationToken)
        } else {
          localStorage.removeItem(AUTH_TOKEN_KEY)
        }
      }))
    },
  }
})

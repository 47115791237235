import { Album } from '@/models/album.ts'
import { useAlbums } from '@/stores/useAlbums.ts'
import { HeyDrive, HeyDriveType } from '@/models/drive.ts'
import { ReactNode } from 'react'
import { getDriveDefaultsFor } from '@/controller/driveDefaults.ts'

export default function AlbumSelection({ externalAlbums, drive, selectedAlbum, setSelectedAlbum, showScrollbar, prependChild, disabled }: { externalAlbums?: Array<Album>, drive: HeyDrive | null, selectedAlbum: Album | null, setSelectedAlbum: (album: Album) => void, showScrollbar?: boolean, prependChild?: ReactNode, disabled?: boolean }) {
  const { albums } = useAlbums()
  const driveDefaults = getDriveDefaultsFor(drive?.drive_type || HeyDriveType.WEDDING)

  console.log('al', externalAlbums)

  return <div className={'gap-3 pl-2 overflow-scroll w-full whitespace-nowrap '
      + (showScrollbar ? '' : 'scrollbar-hide '
      + (disabled ? 'opacity-30' : '') )}
  >
    { prependChild }
    { (externalAlbums || drive!.albums).map(a => <SelectableAlbum
      onClick={() => !disabled && setSelectedAlbum(a)}
      key={a.id}
      album={albums[a.id]!}
      defaultImage={driveDefaults.previewPicture}
      isSelected={selectedAlbum != null && selectedAlbum.id === a.id}
    />) }
  </div>
}

function SelectableAlbum({ album, isSelected, onClick, defaultImage }: { album: Album, isSelected: boolean, onClick: () => void, defaultImage: string }) {
  return <div className='overflow-hidden inline-flex flex-col w-[6rem] mr-3 items-center' onClick={onClick}>
    <div className=' bg-cover bg-center rounded-full w-[4rem] h-[4rem] mb-2' style={{ backgroundImage: album.thumbnail ? `url(${album.thumbnail})` : `url(${defaultImage})` }}>
    </div>
    <div className={
      'rounded-xl text-center text-sm px-2 w-full py-1 border-primary overflow-hidden overflow-ellipsis whitespace-nowrap ' + (isSelected ? 'bg-primary text-neutral' : '')
    }>
      { album.title }
    </div>
  </div>
}

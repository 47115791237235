import { create } from 'zustand'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useProfile } from '@/stores/useAuth.ts'
import axiosRetry from 'axios-retry'

interface UseApiActions {
  get: (path: string) => Promise<AxiosResponse>,
  post: (path: string, data: any) => Promise<AxiosResponse>,
  put: (path: string, data: any) => Promise<AxiosResponse>,
  patch: (path: string, data: any) => Promise<AxiosResponse>,
  delete: (path: string, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
}

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PUBLIC_API_URL,
})

axiosRetry(axiosInstance, {
  retries: 1,
  retryDelay: axiosRetry.linearDelay(200),
  retryCondition: () => true,
})

axiosInstance.interceptors.request.use(function (config) {
  if (useProfile.getState().authenticationToken) {
    config.headers['Authorization'] = `Token ${useProfile.getState().authenticationToken}`
  }

  return config
})

export const useApi = create<UseApiActions>((_) => ({
  get(path: string) {
    return axiosInstance.get(path)
  },
  post(path: string, data) {
    return axiosInstance.post(path, data)
  },
  delete(path: string, config) {
    return axiosInstance.delete(path, config)
  },
  put(path: string, data) {
    return axiosInstance.put(path, data)
  },
  patch(path: string, data) {
    return axiosInstance.patch(path, data)
  },
}))

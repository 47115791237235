import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './style/style.scss'
import { Router } from './Router.tsx'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { UploadProvider } from '@/stores/useUpload.tsx'
import { enableMapSet } from 'immer';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { HeyThemeProvider } from '@/contexts/heyTheme.tsx'
import './i18n.ts'
import * as Sentry from "@sentry/react";
import axiosRetry from 'axios-retry'
import axios from 'axios'

axiosRetry(axios, {
  retries: 1,
  retryDelay: axiosRetry.linearDelay(200),
  retryCondition: () => true,
});

Sentry.init({
  dsn: "https://b88b72ee1c22c9befa7a82a6c5f0eab4@o4508001155809280.ingest.de.sentry.io/4508001159086160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost:8000", import.meta.env.VITE_PUBLIC_API_URL.replace('https://', '').replace('http://', '')],
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient()
enableMapSet()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UploadProvider>
        <HeyThemeProvider>
          <Router />
        </HeyThemeProvider>
      </UploadProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

import { create } from 'zustand'
import { AxiosResponse } from 'axios'
import { useApi } from '@/stores/useApi.ts'
import { Album } from '@/models/album.ts'
import { UploadFile } from '@/stores/useUploadQueue.ts'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { AlbumFileUpload } from '@/models/albumFileUpload.ts'
import { AlbumFile } from '@/models/albumFile.ts'
import { HeyProfile } from '@/models/profile.ts'
import { HeyDrive, HeyDriveType } from '@/models/drive.ts'
import HeyGame from '@/models/heyGame.ts'
import { FullDownload } from '@/models/fullDownload.ts'

interface UseClientActions {
  createGuestProfile: (displayName: string, album: Album) => Promise<AxiosResponse>
  createFileUpload: (album: Album, uploadFile: UploadFile, game?: HeyGame) => Promise<AxiosResponse>
  completeFileUpload: (fileUpload: AlbumFileUpload) => Promise<AxiosResponse>
  completeFileMultipartUpload: (fileUpload: AlbumFileUpload, etags: Array<{part_number: number, etag: string}>) => Promise<AxiosResponse>
  getFiles: (album: Album, filter?: Record<string, string>) => Promise<AxiosResponse>
  getFullDownloads: (drive: HeyDrive) => Promise<AxiosResponse>
  getFullDownloadRequests: (drive: HeyDrive) => Promise<AxiosResponse>
  requestFullDownload: (drive: HeyDrive) => Promise<AxiosResponse>
  deleteFullDownload: (drive: HeyDrive, download: FullDownload) => Promise<AxiosResponse>
  deleteFiles: (album: Album, filter: Record<string, string>) => Promise<AxiosResponse>
  updateFiles: (album: Album, filter: Record<string, string>, data: any) => Promise<AxiosResponse>
  getLiveshow: (album: Album, filter?: Record<string, string>) => Promise<AxiosResponse>
  deleteFile: (file: AlbumFile) => Promise<AxiosResponse>
  updateProfile: (username: string, data: Partial<HeyProfile>) => Promise<AxiosResponse>
  updateDrive: (driveId: string, data: Partial<HeyDrive> | FormData) => Promise<AxiosResponse>
  updateQrCode: (driveId: string, data: Partial<HeyDrive> | FormData) => Promise<AxiosResponse>
  updateAlbum: (albumId: string, data: Partial<Album> | FormData) => Promise<AxiosResponse>
  deleteAlbum: (albumId: string) => Promise<AxiosResponse>
  createAlbum: (driveId: string, data: Partial<Album> | FormData) => Promise<AxiosResponse>
  createGame: (album: Album, challenge: string) => Promise<AxiosResponse>
  updateGame: (gameId: string, challenge: string) => Promise<AxiosResponse>
  deleteGame: (gameId: string) => Promise<AxiosResponse>
  createDemo: (email: string, driveType?: HeyDriveType) => Promise<AxiosResponse>
  sendCoupon: (driveId: string, email: string) => Promise<AxiosResponse>
}

export const useClient = create<UseClientActions>((_) => ({
  createGuestProfile(displayName: string, album: Album) {
    return useApi.getState().post('api/profile/createGuest', {
      display_name: displayName,
      album: album.id,
    })
  },
  createFileUpload(album: Album, uploadFile: UploadFile, game = undefined) {
    const mimeType = uploadFile.file.type || null

    if (mimeType == null) {
      throw 'no_mime_type'
    }

    if (!(mimeType.startsWith('image') || mimeType.startsWith('video'))) {
      throw 'invalid_mime_type'
    }

    return useApi.getState().post(`api/album/${album.id}/upload/`, {
      file_name: uploadFile.file.name,
      file_hash: uploadFile.fileHash,
      game: game ? game.id : null,
      content_type: mimeType,
      size: uploadFile.file.size,
      file_creation: new Date(uploadFile.file.lastModified),
      file_type: mimeType.startsWith('image') ? AlbumFileType.IMAGE : AlbumFileType.VIDEO,
    })
  },
  completeFileUpload(fileUpload: AlbumFileUpload) {
    return useApi.getState().post(`api/uploads/${fileUpload.id}/upload_complete/`, {})
  },
  deleteFullDownload(drive: HeyDrive, download: FullDownload) {
    return useApi.getState().delete(`api/drive/${drive.id}/full_download/?id=${download.id}`)
  },
  requestFullDownload(drive: HeyDrive) {
    return useApi.getState().post(`api/drive/${drive.id}/create_full_download_request/`, {})
  },
  completeFileMultipartUpload(fileUpload: AlbumFileUpload, etags: Array<{part_number: number, etag: string}>) {
    return useApi.getState().post(`api/uploads/${fileUpload.id}/upload_complete/`, {
      etags,
    })
  },
  getFullDownloads(drive: HeyDrive): Promise<AxiosResponse>{
    return useApi.getState().get(`api/drive/${drive.id}/full_downloads/`)
  },
  getFullDownloadRequests(drive: HeyDrive): Promise<AxiosResponse>{
    return useApi.getState().get(`api/drive/${drive.id}/full_download_requests/`)
  },
  getFiles(album: Album, filter = undefined) {
    return useApi.getState().get(`api/album/${album.id}/files/` + (filter ? '?' + new URLSearchParams(filter).toString() : ''))
  },
  deleteFiles(album: Album, filter) {
    return useApi.getState().delete(`api/album/${album.id}/files/?` + new URLSearchParams(filter).toString(), { timeout: 100000 })
  },
  updateFiles(album: Album, filter, data) {
    return useApi.getState().patch(`api/album/${album.id}/files/?` + new URLSearchParams(filter).toString(), data)
  },
  deleteFile(file: AlbumFile) {
    return useApi.getState().delete(`api/files/${file.id}/`)
  },
  updateProfile(username: string, data: Partial<HeyProfile>) {
    return useApi.getState().patch(`api/profile/${username}/`, data)
  },
  updateDrive(driveId: string, data: Partial<HeyDrive> | FormData) {
    return useApi.getState().patch(`api/drive/${driveId}/`, data)
  },
  updateQrCode(driveId: string, data: Partial<HeyDrive> | FormData) {
    return useApi.getState().patch(`api/drive/${driveId}/update_qr_code/`, data)
  },
  updateAlbum(albumId: string, data: Partial<Album> | FormData) {
    return useApi.getState().patch(`api/album/${albumId}/`, data)
  },
  createAlbum(driveId: string, data: Partial<Album> | FormData) {
    return useApi.getState().post(`api/drive/${driveId}/create_album/`, data)
  },
  createGame(album: Album, challenge: string) {
    return useApi.getState().post(`api/album/${album.id}/create_game/`, {
      challenge,
    })
  },
  updateGame(gameId: string, challenge: string) {
    return useApi.getState().patch(`api/game/${gameId}/`, {
      challenge,
    })
  },
  deleteGame(gameId: string) {
    return useApi.getState().delete(`api/game/${gameId}/`)
  },
  createDemo(email: string, driveType?: HeyDriveType) {
    const demoData: Record<string, string> = { email }
    if (driveType) {
      demoData['drive_type'] = driveType
    }

    return useApi.getState().post(`api/demo/`, demoData)
  },
  getLiveshow(album: Album, filter?: Record<string, string>) {
    return useApi.getState().get(`api/album/${album.id}/liveshow/` + (filter ? '?' + new URLSearchParams(filter).toString() : ''))
  },
  deleteAlbum(albumId: string) {
    return useApi.getState().delete(`api/album/${albumId}/`)
  },
  sendCoupon(driveId: string, email: string) {
    return useApi.getState().post(`api/drive_coupon/`, { email, drive: driveId })
  },
}))

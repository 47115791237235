import { useFilesContext } from '@/contexts/filesContext.tsx'
import { MyGameFilesProvider } from '@/contexts/myGameFilesContext.tsx'
import { useOutlet } from 'react-router-dom'
import LoadBig from '@/components/LoadBig/LoadBig.tsx'

export default function GamesPage() {
  const { currentAlbum: album } = useFilesContext()!
  const outlet = useOutlet()

  if (!album) {
    return <LoadBig />
  }

  return <MyGameFilesProvider album={album}>
    { outlet }
  </MyGameFilesProvider>
}

import { Album } from '@/models/album.ts'
import HeyGame from '@/models/heyGame.ts'
import { useMyGameFilesContext } from '@/contexts/myGameFilesContext.tsx'
import { useRef, useState } from 'react'
import { isStatusFinalState, useUploadQueue } from '@/stores/useUploadQueue.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { CurrentGameFilesProvider, useCurrentGameFilesContext } from '@/contexts/currentGameFilesContext.tsx'
import { AlbumFile } from '@/models/albumFile.ts'
import { IconArrowBack, IconCheck } from '@tabler/icons-react'
import { t } from 'i18next'
import { Button } from 'react-daisyui'
import FilesListing from '@/components/Files/FilesListing.tsx'
import FilesSlideshow from '@/components/Files/FilesSlideshow.tsx'
import CreateProfileModal from '@/components/CreateProfileModal.tsx'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import LoadBig from '@/components/LoadBig/LoadBig.tsx'
import DriveHeader from '@/components/DriveHeader.tsx'
import { useDrives } from '@/stores/useDrives.ts'

export default function GamePage() {
  const { currentDrive } = useDrives()
  const { currentAlbum: album } = useFilesContext()!
  const { gameId } = useParams<{gameId: string}>()
  const navigate = useNavigate()

  if (!album) {
    return <LoadBig />
  }

  const currentGame = album.games.find(game => game.id === gameId) || null
  if (!currentGame) {
    navigate(`/d/${currentDrive!.id}/${album.id}/game`)
    return
  }

  function onBackClick() {
    navigate(`/d/${currentDrive!.id}/${album!.id}/game`)
  }

  return <CurrentGameFilesProvider album={album} game={currentGame}>
    <GamePageContent album={album} game={currentGame} onBackClick={onBackClick} />
  </CurrentGameFilesProvider>
}

function GamePageContent({ album, game, onBackClick }: { album: Album, game: HeyGame, onBackClick: () => void }) {
  const { currentFilesCollection } = useMyGameFilesContext()!
  const profileCreateRef = useRef<HTMLDialogElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { registerFileUploads, uploadQueue } = useUploadQueue()
  const { profile } = useProfile()
  const currentlyUploading = uploadQueue.find(file => file.game && file.game.id === game.id && !isStatusFinalState(file.status))
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null)

  const entry = currentFilesCollection!.files.find(file => file.game && file.game.id === game.id)
  const { currentFilesCollection: allFilesCollection } = useCurrentGameFilesContext()!

  function onPlayClick() {
    if (!profile) {
      profileCreateRef.current?.showModal()
      return
    }

    if (currentlyUploading) {
      return
    }

    fileInputRef.current?.click()
  }

  function onRegister() {
    setTimeout(() => {
      fileInputRef.current?.click()
    }, 200)
  }

  function onFileSelect(e: any) {
    if (e.target.files.length > 0) {
      registerFileUploads(album!, [{
        file: e.target.files[0],
        game: game,
      }])
    }
  }

  function onFileClick(file: AlbumFile) {
    console.log('click', file)
    const indexOfFile = allFilesCollection!.files.findIndex((f: any) => f.id === file.id)
    if (indexOfFile != null) {
      setSelectedFileIndex(indexOfFile)
    }
  }

  return <>
    <DriveHeader />
    <div className='container px-4 mx-auto flex flex-col gap-3 items-center relative'>
      <div className='text-sm flex flex-col items-center text-primary absolute left-4 top-[50%] translate-y-[-50%]' onClick={onBackClick}>
        <IconArrowBack />
        {t('common.back')}
      </div>
      <h2 className='font-serif text-xl mb-3 max-w-[70%] text-center'>{ game.challenge }</h2>

    </div>
    <div className='container px-4 mx-auto flex flex-col gap-3 items-center relative mt-[2rem]'>
      { !entry && <>
        <p className='text-sm'>{t('games.notCompleted')}</p>
        <Button loading={!!currentlyUploading} disabled={!!currentlyUploading} color='primary' onClick={onPlayClick}>{t('games.upload')}</Button>
      </>
      }
      {
        entry && <>
          <div className='flex flex-row gap-2 items-center mb-3'>
            <div className='bg-primary text-neutral rounded-full p-1'>
              <IconCheck />
            </div>
            <p className='text-sm text-center'>{t('games.completed')}</p>
          </div>
        </>
      }
      {
        allFilesCollection && allFilesCollection.files.length === 0 && <p className='mt-5'>
          {t('games.beFirst')}
        </p>
      }
    </div>
    <div className='py-5'>
      { allFilesCollection && allFilesCollection.files.length > 0 && <>
        <FilesListing
          files={allFilesCollection}
          onFileClick={onFileClick}
        />
        <FilesSlideshow files={allFilesCollection.files} selectedFileIndex={selectedFileIndex}
                        onSelectFileIndex={setSelectedFileIndex}/>
      </> }
    </div>
    <input ref={fileInputRef} type="file" accept='image/jpeg,image/png' onInput={onFileSelect} style={{ display: 'none' }} />
    <CreateProfileModal album={album!} innerRef={profileCreateRef} onRegister={onRegister} />
  </>
}

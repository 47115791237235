import { AlbumFileType } from '@/models/albumFileType'
import { Slide } from 'yet-another-react-lightbox'
import HeyGame from '@/models/heyGame.ts'

export interface AlbumFile {
  id: string
  author: string
  author_display_name: string
  file: string
  file_type: AlbumFileType
  content_type: string
  thumbnail: string | null
  detail: string
  game: HeyGame | null
  is_demo?: boolean
  file_creation: string | null
  created_at: string
  video_duration_seconds?: number | null
}

export function prepareForLightbox(file: AlbumFile): Slide {
  if (file.file_type === AlbumFileType.IMAGE) {
    if (file.is_demo) {
      return {
        src: file.detail ? file.detail : file.file,
        share: file.detail ? file.detail : file.file,
        description: 'Demo',
        download: file.file,
      }
    }

    return {
      src: file.detail ? file.detail : file.file,
      share: file.detail ? file.detail : file.file,
      download: `${import.meta.env.VITE_PUBLIC_API_URL}/api/files/${file.id}/download/`,
      description: `${new Date(file.file_creation || file.created_at).toLocaleString()} - ${file.author_display_name}`,
    }
  }

  if (file.is_demo) {
    return {
      type: 'video',
      sources: [
        {
          src: file.detail ? file.detail : file.file,
          type: file.content_type,
        },
      ],
      share: file.detail ? file.detail : file.file,
      download: file.file,
      description: `Demo`,
    }
  }

  return {
    type: 'video',
    sources: [
      {
        src: file.file,
        type: file.content_type,
      },
      ...(file.detail ?[{
        src: file.detail,
        type: 'video/mp4',
      }] : [])
    ],
    // TODO: Check if this works on large videos
    share: file.detail ? file.detail : file.file,
    download: `${import.meta.env.VITE_PUBLIC_API_URL}/api/files/${file.id}/download/`,
    description: `${new Date(file.file_creation || file.created_at).toLocaleString()} - ${file.author_display_name}`,
  }
}

export interface ThemeDetails {
  primary: string
  neutral: string
  content: string
}

export const defaultThemes: Array<ThemeDetails> = [
  {
    primary: '#781140',
    neutral: '#f5f1ef',
    content: '#040404',
  },
  {
    primary: '#5A7447',
    neutral: '#E6F0DD',
    content: '#040404',
  },
  {
    primary: '#122B57',
    neutral: '#F4F1EF',
    content: '#040404',
  },
  {
    primary: '#F89584',
    neutral: '#F8F2EC',
    content: '#2F2D2D',
  },
  {
    primary: '#41444B',
    neutral: '#DFD8C8',
    content: '#52575D',
  },
  {
    primary: '#CCC094',
    neutral: '#F1F1EF',
    content: '#403D3D',
  },
]

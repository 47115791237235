import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App.tsx'
import { AlbumPage } from '@/pages/Album.page.tsx'
import { DrivePage } from '@/pages/Drive.page.tsx'
import SettingsPage from '@/pages/Settings.page.tsx'
import GamesPage from '@/pages/Games.page.tsx'
import DemoPage from '@/pages/Demo.page.tsx'
import LiveshowPage from '@/pages/Liveshow.page.tsx'
import YesIDoPage from '@/pages/YesIDo.page.tsx'
import OnboardingPage from '@/pages/Onboarding.page.tsx'
import GenericDrivePage from '@/pages/GenericDrive.page.tsx'
import GamePage from '@/pages/Game.page.tsx'
import GamesOverviewPage from '@/pages/GameOverview.page.tsx'

const albumRoutes = [
  {
    path: '',
    element: <AlbumPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />
  },
  {
    path: 'game/',
    element: <GamesPage />,
    children: [
      {
        path: '',
        element: <GamesOverviewPage />,
      },
      {
        path: ':gameId/',
        element: <GamePage />,
      }
    ],
  },
  {
    path: 'liveshow',
    element: <LiveshowPage />
  },
  {
    path: 'yes',
    element: <YesIDoPage />,
  },
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/d/:driveId',
    element: <DrivePage />,
    children: [
      {
        path: '',
        element: <GenericDrivePage />,
        children: [
          {
            path: ':albumId',
            children: albumRoutes,
          },
          ...albumRoutes,
          {
            path: 'onboarding',
            element: <OnboardingPage />,
          },
        ],
      },
    ],
  },
  {
    path: 'demo',
    element: <DemoPage />,
  }
]);

export function Router() {
  return <RouterProvider router={router} />;
}

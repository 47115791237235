export enum FullDownloadRequestStatus {
  TODO = 'todo',
  NEEDS_CONFIRMATION = 'needs_confirm',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  DONE = 'done',
}

export interface FullDownloadRequest {
  id: string
  created_at: string
  status: FullDownloadRequestStatus
  result: string
  result_size: number
  files_count: number
  projected_uncompressed_size: number
  projected_time_total_sec: number
}

import { create } from 'zustand'
import { produce } from 'immer'
import { HeyDrive } from '@/models/drive.ts'

interface UseDrivesState {
  drives: Record<string, HeyDrive>
  currentDrive: HeyDrive | null
}

interface UseDrivesActions {
  setDrive: (drive: HeyDrive) => void
  setCurrentDrive: (drive: HeyDrive | null) => void
}

export const useDrives = create<UseDrivesState & UseDrivesActions>((set) => ({
  drives: {},
  currentDrive: null,
  setDrive(drive) {
    set(produce(state => {
      state.drives[drive.id] = drive
    }))
  },
  setCurrentDrive(drive) {
    set(produce(state => {
      state.currentDrive = drive
    }))
  },
}))

import { Album } from '@/models/album.ts'
import React, { createContext, useContext } from 'react'
import { FilesContext, FilesProvider } from '@/contexts/filesContext.tsx'
import { useProfile } from '@/stores/useAuth.ts'


export const MyGameFilesContext = createContext<FilesContext | null>(null)

export function MyGameFilesProvider({ children, album }: { children: React.ReactNode, album: Album }) {
  const { profile } = useProfile()

  return <FilesProvider albumId={profile ? album.id : undefined} filesFilter={profile ? { author__username: profile.username, all: '1' } : undefined} FilesContext={MyGameFilesContext}>
    { children }
  </FilesProvider>
}

export function useMyGameFilesContext() {
  return useContext(MyGameFilesContext)
}
